import { React, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import tableElements from 'utils/KubenAdmin/FilesPage/tableElements';
import filesTableMenu from 'utils/KubenAdmin/FilesPage/filesTableMenu';
import useFetchFiles from 'hooks/api/file/useFetchFiles';
import useCreateFile from 'hooks/api/file/useCreateFile';
import useUpdateFile from 'hooks/api/file/useUpdateFile';
import useDeleteFile from 'hooks/api/file/useDeleteFile';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';

import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UITable from 'components/KubenAdmin/UITable/UITable';
import UITableElementWrapper from 'components/KubenAdmin/UITable/UITableElementWrapper';
import UITableElement from 'components/KubenAdmin/UITable/UITableElement';
import UITableMenu from 'components/KubenAdmin/UITable/UITableMenu';
import UIConfirmModal from 'components/KubenAdmin/UIAdmin/UIConfirmModal';

const FilesPage = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const hiddenFileInputRef = useRef(null);
  const [selected, setSelected] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);

  const {
    isLoading: isFilesListLoading,
    isError: isFilesListError,
    data: filesList,
    refetch: refetchFiles,
  } = useFetchFiles();
  const {
    isLoading: createFileIsLoading,
    isError: createFileIsError,
    mutate: mutateCreateFile,
    isSuccess: isCreateFileSuccess,
  } = useCreateFile(file);
  const {
    isLoading: updateFileIsLoading,
    isError: updateFileIsError,
    mutate: mutateUpdateFile,
    isSuccess: isUpdateFileSuccess,
  } = useUpdateFile(file);
  const {
    isLoading: deleteFileIsLoading,
    isError: deleteFileIsError,
    mutate: mutateDeleteFile,
    isSuccess: isDeleteFileSuccess,
  } = useDeleteFile(file);

  const handleFilesClick = (event, fileId) => {
    if (event === 'update') {
      hiddenFileInputRef.current.id = fileId;
      hiddenFileInputRef.current.click();
    } else {
      const fileToDelete = filesList.find((file) => file.id === fileId);
      setDeleteFile(fileToDelete);
    }
  };

  const selectFileImport = (e) => {
    const targetFile = e.target.files[0];

    if (!targetFile) {
      return;
    }

    const formData = new FormData();
    formData.append('Name', targetFile.name);
    formData.append('File', targetFile);

    const fileId = e.target.id;
    if (fileId !== 'undefined') {
      formData.append('Id', fileId);
    }

    setFile(formData);
  };

  const onDelete = (id) => {
    mutateDeleteFile(id);
    setSelected(null);
    setDeleteFile(null);
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    if (file.has('Id')) {
      mutateUpdateFile();
    } else {
      mutateCreateFile();
    }
  }, [file]);

  useEffect(() => {
    refetchFiles();
  }, [isCreateFileSuccess, isUpdateFileSuccess, isDeleteFileSuccess]);

  if (isFilesListLoading || createFileIsLoading || updateFileIsLoading || deleteFileIsLoading) {
    return (
      <UILoadingModal
      />
    );
  }

  if (isFilesListError || createFileIsError || updateFileIsError || deleteFileIsError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UITitle
        title="FILER"
        isButton={true}
        buttonName="Importera"
        onClick={() => handleFilesClick('update')}
      />
      <input
        className="hidden"
        type="file"
        ref={hiddenFileInputRef}
        onChange={(e) => selectFileImport(e)}
      />
      {deleteFile && (
        <UIConfirmModal
          title={`Är du säker på att du vill radera '${deleteFile.name}'?`}
          onCancel={() => setDeleteFile(null)}
          onConfirm={() => onDelete(deleteFile.id)}
        />
      )}
      <UITable tableElements={tableElements}>
        {filesList &&
          filesList.map((el, idx) => (
            <UITableElementWrapper key={idx}>
              <UITableElement
                name={el.name}
                maxLength={100}
              />
              <UITableElement
                isMultiple={true}
                multipleData={el.data}
                isNotFullWidth={true}
              />
              <UITableMenu
                handleClick={(e) => handleFilesClick(e, el.id)}
                isOpen={selected === el.id}
                setOpen={() => setSelected(selected === el.id ? null : el.id)}
                data={filesTableMenu}
              />
            </UITableElementWrapper>
          ))}
      </UITable>
    </UIStaticComponentsWrapper>
  );
};

export default FilesPage;
