import React from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { TiWarning } from 'react-icons/ti';
import SortableTree from '@nosferatu500/react-sortable-tree';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import useEditRegionTreeNode from 'hooks/api/region/useEditRegionTreeNode';
import findParentId from 'utils/KubenAdmin/RegionsPage/findParentId';
import { modifyedRegionData as modifiedRegionDataRecoil } from 'state/KubenAdmin/regions';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';

import '@nosferatu500/react-sortable-tree/style.css';

const RegionDraggableTree = ({ array, setArray, onOpenFormPage, onDelete }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { isLoading, isError, mutate } = useEditRegionTreeNode();

  const setModifiedRegionData = useSetRecoilState(modifiedRegionDataRecoil);

  const canNodeHaveChildren = (node) => {
    if (node.$type === 'unit') {
      return false;
    }
    return true;
  };

  const onMoveNode = (node) => {
    setModifiedRegionData((prevData) => {
      const parentId = findParentId(prevData, node.node.id);

      const regionTreeNode = {
        regionId: id,
        parentId: parentId,
        node: node.node,
      };

      mutate(regionTreeNode);

      return prevData;
    });
  };

  const canDropNode = (node) => {
    if (node.node.$type === 'organizationUnit') {
      return (
        (node.prevParent?.rights.canUpdate || true) &&
        (node.nextParent?.rights.canUpdate || node.nextParent?.rights.canUpdate == null) &&
        (node.node.logicalType === node.nextParent?.logicalType || node.nextParent?.logicalType == null)
      );
    }

    return (
      node.prevParent.rights.canUpdate &&
      node.nextParent?.rights.canUpdate &&
      node.prevParent.logicalType === node.nextParent?.logicalType
    );
  };

  const renderButtons = (node) => {
    return (
      <div>
        {node.rights.canUpdate && (
          <>
            <button onClick={() => onOpenFormPage(node, 'edit')}>Redigera</button>
          </>
        )}
        {node.rights.canUpdate && canNodeHaveChildren(node) && (
          <>
            <span className="mx-2">/</span>
            <button onClick={() => onOpenFormPage(node, 'add')}>Lägg till</button>
          </>
        )}
        {node.rights.canDelete && (
          <>
            <span className="mx-2">/</span>
            <button onClick={() => onDelete(node)}>Radera</button>
          </>
        )}
      </div>
    );
  };

  if (isLoading) {
    return (
      <UILoadingModal
      />
    );
  }

  if (isError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  /* SortableTree causing ResizeObserver loop limit exceeded */

  return (
    <SortableTree
      treeData={array.children}
      canNodeHaveChildren={(node) => canNodeHaveChildren(node)}
      canDrop={(node) => canDropNode(node)}
      onChange={(treeData) => setArray({ ...array, children: treeData })}
      onMoveNode={onMoveNode}
      generateNodeProps={({ node }) => ({
        title: (
          <div
            className={`flex justify-between items-center md:max-w-[650px] w-[650px] px-4 h-full
              ${node.disabled ? 'disabled' : ''} `}
          >
            <div className="flex items-center">
              {node.title}
              {node.interactionNeeded && (
                <div className="relative group">
                  <TiWarning className="error ml-2 text-lg" />
                  <div
                    className={
                      'group-hover:block absolute hidden node shadow-lg p-2 border z-20 ' +
                      'text-xs left-8 font-normal rounded max-w-[200px] ' +
                      `${node.$type === 'unit' ? 'h-[50px] -top-4' : '-top-2'}`
                    }
                  >
                    {node.$type === 'organizationUnit' ? (
                      <p>Element behöver redigeras</p>
                    ) : (
                      <p>
                        Område & nattbemanning matchar
                        <br /> inte med verksamhetstyp
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
            {renderButtons(node)}
          </div>
        ),
      })}
    />
  );
};

RegionDraggableTree.propTypes = {
  array: propTypes.any.isRequired,
  setArray: propTypes.func.isRequired,
  onDelete: propTypes.func.isRequired,
  onOpenFormPage: propTypes.func.isRequired,
};

export default RegionDraggableTree;
