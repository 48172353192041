import { React, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { IoFilterSharp } from 'react-icons/io5';

import { IsOverlayActive } from 'state/global/overlay';
import { graphFilters as graphFiltersRecoil } from 'state/KubenDashboard/graphFilters';
import extractFiltersNames from 'utils/KubenDashboard/extractFiltersName';
import closeElementFunction from 'utils/global/closeElementFunction';

import { modalFilterTabs } from './GraphFiltersModal/GraphFiltersTabs';
import GraphFilterDropdownElement from './GraphFilterDropdownElement';
import GraphFiltersModal from './GraphFiltersModal/GraphFiltersModal';

const GraphFilters = ({ isScroll, filters }) => {
  const [chosenFilters, setChosenFilters] = useRecoilState(graphFiltersRecoil);
  const [isModalOpen, setModalOpen] = useState(false);
  const setIsDashboardOverlayVisible = useSetRecoilState(IsOverlayActive);
  const wrapperRef = useRef(null);
  closeElementFunction(wrapperRef, setModalOpen);

  useEffect(() => {
    setIsDashboardOverlayVisible(isModalOpen);
  }, [isModalOpen]);

  const removeFilter = (filter, tableKey) => {
    const filteredArray = chosenFilters[tableKey].filter((el) => el !== filter);
    if (filteredArray.length === 0) {
      setIsDashboardOverlayVisible(false);
    }
    setChosenFilters({ ...chosenFilters, [tableKey]: filteredArray });
  };

  const handleFilterClick = () => {
    if (!isModalOpen) {
      setModalOpen(true);
    }
  };

  return (
      <div className="flex space-x-2 w-fit">
        <div className="relative h-full">
          <button
            onClick={handleFilterClick}
            className={`graph-filter flex items-center justify-evenly rounded-md 
            p-2 h-full relative
            ${isModalOpen ? 'z-40 relative' : 'z-0'}
             ${isScroll ? 'is-scroll' : ''}
             `}
          >
            <IoFilterSharp className="mr-2" />
            Filter
          </button>
          {isModalOpen && (
            <div ref={wrapperRef}>
              <GraphFiltersModal
                onCloseModal={() => setModalOpen(false)}
                filters={filters}
              />
            </div>
          )}
        </div>
        <div className="flex w-full space-x-3">
          {modalFilterTabs
            .filter((filterTab) => chosenFilters[filterTab.tab].length > 0)
            .map((tab) => (
              <GraphFilterDropdownElement
                key={tab.id}
                title={tab.title}
                removeFilter={removeFilter}
                chosenFilters={extractFiltersNames(filters[tab.tab], chosenFilters[tab.tab])}
                tableKey={tab.tab}
              />
            ))}
        </div>
      </div>
  );
};

GraphFilters.propTypes = {
  isScroll: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
};

export default GraphFilters;
