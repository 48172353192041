/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Prompt, useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import doesElementsAreEqual from 'utils/KubenAdmin/doesElementsAreEqual';

import UIConfirmModal from './UIConfirmModal';

const UIModalWithoutSaveData = ({ elementToCompare, orginalElement }) => {
  const history = useHistory();
  const currentLocation = useLocation();
  const matchLocation = useRouteMatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isNavigationBlocked, setIsNavigationBlocked] = useState(true);
  const [navigationRoute, setNavigationRoute] = useState(null);

  const onCancel = () => {
    setModalOpen(false);
    setIsNavigationBlocked(false);
    setTimeout(() => {
      history.push(navigationRoute);
    }, 0);
  };

  const onConfirm = () => {
    setModalOpen(false);
    setNavigationRoute(null);
  };

  const openModal = (route) => {
    setModalOpen(true);
    setNavigationRoute(route);
  };

  const handlePrompt = (location) => {
    const isCurrentPathIsRegionDetails = currentLocation.pathname === `/admin/regions/${matchLocation.params.id}`;
    const isLocationPathIsRegionDetailsEdit =
      location.pathname === `/admin/regions/${matchLocation.params.id}/edit`;
    const isLocationPathIsRegionDetailsAdd =
      location.pathname === `/admin/regions/${matchLocation.params.id}/add`;

    if (
      !doesElementsAreEqual(elementToCompare, orginalElement) &&
      isNavigationBlocked &&
      !(isCurrentPathIsRegionDetails && isLocationPathIsRegionDetailsEdit) &&
      !(isCurrentPathIsRegionDetails && isLocationPathIsRegionDetailsAdd)
    ) {
      openModal(location.pathname);
      return false; // Prevent navigation
    }
    return true; // Allow navigation
  };

  return (
    <>
      <Prompt
        when={isNavigationBlocked}
        message={handlePrompt}
      />
      {isModalOpen && (
        <UIConfirmModal
          title="Du har osparade ändringar. Är du säker på att du vill lämna sidan?"
          onCancel={onCancel}
          onConfirm={onConfirm}
          onCancelButtonName="Lämna sidan"
          onConfirmButtonName="Avbryt"
          widerModal={true}
        />
      )}
    </>
  );
};

UIModalWithoutSaveData.propTypes = {
  elementToCompare: PropTypes.any.isRequired,
  orginalElement: PropTypes.any.isRequired,
};

export default UIModalWithoutSaveData;
