import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowLeft } from 'react-icons/bs';
import { useRecoilState, useRecoilValue } from 'recoil';

import { currentStartPagePinnedIds, currentUser, startPageItems } from 'state/global/currentUser';
import { newsState } from 'state/global/news';
import { editGraphLayout } from 'state/KubenDashboard/graphs';

import useFetchUserDashboardComponents from 'hooks/api/user/useFetchUserDashboardComponents';
import useSaveUserProfile from 'hooks/api/user/useSaveUserProfile';
import useFetchNews from 'hooks/api/news/useFetchNews';

import UIWarningModal from 'components/global/UIModals/UIWarningModal';
import AppNewsList from 'components/global/AppNewsList/AppNewsList';
import StartPageEditButton from 'components/global/StartPage/StartPageEditButton';
import StartPagePinnedComponents from 'components/global/StartPage/StartPagePinnedComponents';
import UISpinner from 'components/global/UICommon/UISpinner';

const StartPage = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(currentUser);
  const news = useRecoilValue(newsState);

  const [isNews, setIsNews] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [isEdit, setIsEdit] = useRecoilState(editGraphLayout);

  const [pinnedIds, setPinnedIds] = useRecoilState(currentStartPagePinnedIds);
  const pinnedItems = useRecoilValue(startPageItems);
  const [currentPinned, setCurrentPinned] = useState([]);

  const { isLoading: isPinnedItemsLoading } = useFetchUserDashboardComponents();
  const { isLoading: isNewsLoading } = useFetchNews();
  const { 
    isLoading: isSaveUserLoading,
     mutate: mutateSaveUserProfile
  } = useSaveUserProfile(pinnedIds);

  const isLoading = (isPinnedItemsLoading || isSaveUserLoading || isNewsLoading);

  useEffect(() => {
    if (user?.userProfile) {
      setPinnedIds(user.userProfile.pinnedLandingPageComponentIds);
    }
  }, [user]);

  useEffect(() => {
    if (pinnedItems.length == 0) {
      return;
    }

    const tmpPinned = pinnedItems.flatMap(category => 
      category.landingPageComponents.map(item => ({
          ...item,
          appTitle: category.title,
          appName: category.name
      }))
    ).filter(item => pinnedIds.includes(item.id));
    setCurrentPinned(tmpPinned);

  }, [pinnedIds, pinnedItems]);

  const onRemove = (elementId) => {
    const ids = [...pinnedIds];
    const selectedElementIndex = ids.indexOf(elementId);
    ids.splice(selectedElementIndex, 1);
    setPinnedIds([...ids]);
  };

  const handleEditClicked = (actionType) => {
    if (actionType === 'open') {
        setIsNews(false);
        setIsEdit(true);
    } else if (actionType === 'save') {
        setIsEdit(false);
        mutateSaveUserProfile();
    } else if (actionType === 'cancel') {
        setIsEdit(false);
        setPinnedIds(pinnedIds);
    } 
  };


  return (
    <div className="flex justify-center items-center h-full w-full relative start-page">
      <StartPageEditButton
        onClick={(actionType) => handleEditClicked(actionType)}
        isEdit={isEdit}
      />
      <UIWarningModal
        setOpen={setIsEditModalOpen}
        isOpen={isEditModalOpen}
        modalTitle={t('UIModals.editModalTitle')}
        modalMessage={t('UIModals.errorModalMessage')}
        isConfirmButton={false}
        closeButtonName={t('UIBackButton.goBackText')}
      />
        <div
          className='flex flex-col space-y-3
          transition-all ease-in-out duration-300
          w-3/4 h-5/6'
        >
          <div className="text-left w-full flex justify-between items-center mb-4">
            <div>
              <h2 className="text-2xl font-medium title secondary">{t('DashboardPage.header.title')}</h2>
              <p className="text-md subtitle">{t('DashboardPage.header.subtitle')}</p>
            </div>
            <button
              className='flex items-center border p-2 space-x-2 rounded-lg'
              onClick={() => setIsNews(!isNews)}
            >
              <label>
                {t('DashboardPage.news.header')}
              </label>
              <BsArrowLeft
                className={`${isNews ? 'rotate-180' : ''}`}
              />
            </button>
          </div>
          {isLoading ? 
          <div
            className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
          >
            <UISpinner
              theme='fast'
            />
            </div>
            :
            <div
              className={`flex h-full w-full ${isNews ? 'space-x-4' : ''}`}
            >
              <StartPagePinnedComponents
                items={currentPinned}
                isEdit={isEdit}
                onRemove={onRemove}
              />
              {isNews && <AppNewsList
                newsItems={news}
              />}
            </div>
          }
        </div>
      </div>
  );
};

export default StartPage;
