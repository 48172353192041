import React from 'react';
import propTypes from 'prop-types';
import formatNumber from 'utils/KubenDashboard/formatNumber';

const DashboardChartToolTip = ({ toolTipData, chartData, hasXTicks }) => {
  const { active, label, payload } = toolTipData;

  if (active && payload) {
    return (
      <div className="chart-tooltip rounded-lg p-2 text-center border opacity-100 whitespace-nowrap">
        <div className="text-xl font-bold">{hasXTicks ? label : chartData.data[label]?.name}</div>
        {payload.map((p, idx) => {
          const title = chartData.titles.find((t) => t.id == p.dataKey);
          return (
            <div
              key={idx}
              className="flex space-x-2 items-center"
            >
              <div
                style={{
                  backgroundColor: title.color,
                }}
                className="w-3 h-3 rounded-full border-counter-secondary border "
              ></div>
              <div className="font-md">{title.name}</div>
              <div className="flex">
                <div className="text-x2xl font-bold mr-1">{formatNumber(p.value)}</div>
                <div>{chartData.units}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

DashboardChartToolTip.propTypes = {
  toolTipData: propTypes.any,
  chartData: propTypes.any,
  hasXTicks: propTypes.bool,
};

export default DashboardChartToolTip;
