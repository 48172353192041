import React, { useCallback, useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { useRecoilState, useRecoilValue } from 'recoil';
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, LineChart, Line, Tooltip } from 'recharts';
import { graphDrillDownData, graphXTicks } from 'state/KubenDashboard/graphs';
import formatNumber from 'utils/KubenDashboard/formatNumber';
import GraphXAxisLabelOption from 'config/graphXAxisLabelOption';
import DashboardChartToolTip from './DashboardChartToolTip';

const DashboardLineChart = ({ chartData, activeTitles, isEdit }) => {
  const [drillDownData, setDrillDownData] = useRecoilState(graphDrillDownData);
  const [focusDot, setFocusDot] = useState(null);
  const [maxYTickLength, setMaxYTickLength] = useState(-1);
  const xTickState = useRecoilValue(graphXTicks);

  const XTickFormatter = useCallback((value, index) => {
    return xTickState === GraphXAxisLabelOption.FULL
      ? value
      : xTickState == GraphXAxisLabelOption.INDEX
      ? index
      : value.substring(0, 3);
  }, [xTickState]);

  const YTickFormatter = (value) => `${formatNumber(value)} ${chartData.units}`;

  const formattedYLabels = useMemo(() => {
    return chartData.data.map((dataPoint) => 
      `${formatNumber(dataPoint.value)} ${chartData.units}`
    );
  }, [chartData.data, chartData.units]);

  useEffect(() => {
    const maxLabelLength = Math.max(...formattedYLabels.map((label) => label.length));
    setMaxYTickLength(maxLabelLength);
  }, [formattedYLabels]);
  
  const onHover = useCallback((data, title) => {
    if (!isEdit) {
      setFocusDot({
        data: data.payload.drillDownDataKey,
        title: title.drillDownTitleKey,
      });
    }
  }, [isEdit]);
  
  const onClick = useCallback((data, title) => {
    if (
      !isEdit &&
      (data.payload.drillDownDataKey !== drillDownData.data || title.drillDownTitleKey !== drillDownData.title)
    ) {
      setDrillDownData({
        graphId: chartData.id,
        title: title.drillDownTitleKey,
        titleLabel: data.payload.name,
        data: data.payload.drillDownDataKey,
        dataLabel: title.name,
      });
    }
  }, [isEdit, drillDownData, setDrillDownData, chartData.id]);


  const getCustomDot = (props, title) => {
    // eslint-disable-next-line react/prop-types
    const { r, cx, cy, value, payload, stroke, strokeWidth, fill } = props;
    let width = strokeWidth;

    if (
      // eslint-disable-next-line react/prop-types, max-len
      (focusDot?.data == payload.drillDownDataKey && focusDot?.title == title.drillDownTitleKey) ||
      // eslint-disable-next-line react/prop-types
      (drillDownData.data == payload.drillDownDataKey && drillDownData.title == title.drillDownTitleKey)
    ) {
      width = 10;
    }

    if (value !== undefined) {
      return (
        <circle
          key={`${cx}${cy}`}
          cx={cx}
          cy={cy}
          r={r}
          stroke={stroke}
          strokeWidth={width}
          fill={fill}
        />
      );
    }
  };

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      animationDuration={0}
    >
      <LineChart
        data={chartData.data}
        margin={{
          top: 10,
          right: 25,
          left: 5,
          bottom: xTickState !== GraphXAxisLabelOption.NONE ? 5 : 0,
        }}
      >
        <CartesianGrid vertical={false} />
        {xTickState !== GraphXAxisLabelOption.NONE && (
          <XAxis
            dataKey="name"
            tickMargin={10}
            tick={{ fill: 'hsl(0, 0%, 60%)' }}
            axisLine={false}
            tickLine={false}
            tickFormatter={XTickFormatter}
          />
        )}
        <YAxis
          tickFormatter={YTickFormatter}
          animationDuration={0}
          axisLine={false}
          tickLine={false}
          tickSize={10}
          width={maxYTickLength * 8.8}
          tick={{ fill: 'hsl(0, 0%, 60%)' }}
          style={{
            fontSize: '14px',
          }}
        />
        {!isEdit && <Tooltip
          offset={30}
          animationDuration={500}
          cursor={false}
          content={(data) => (
            <DashboardChartToolTip
              toolTipData={data}
              chartData={chartData}
              hasXTicks={xTickState !== GraphXAxisLabelOption.NONE}
            />
          )}
        />}
        {chartData.titles.map((title, idx) => (
          <Line
            activeDot={{
              onClick: (func, data) => onClick(data, title),
              onMouseMove: (func, data) => onHover(data, title),
              onMouseLeave: () => setFocusDot(null),
            }}
            dot={(data) => getCustomDot(data, title)}
            key={idx}
            dataKey={title.id}
            type={chartData.options.chartType}
            stroke={title.color}
            strokeWidth={3}
            hide={!activeTitles.includes(title.id)}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

DashboardLineChart.propTypes = {
  chartData: propTypes.any,
  activeTitles: propTypes.array.isRequired,
  isEdit: propTypes.bool.isRequired,
};

export default DashboardLineChart;
