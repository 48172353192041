import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import { useRecoilState } from 'recoil';

import { graphFilters as graphFiltersRecoil } from 'state/KubenDashboard/graphFilters';

import GraphFiltersTabs from './GraphFiltersTabs';
import GraphFiltersChoices from './GraphFiltersChoices';
import GraphFiltersButtons from './GraphFiltersButtons';
import GraphFiltersSearch from './GraphFiltersSearch';
import GraphFiltersCheckboxes from './GraphFiltersCheckboxes';

const GraphFiltersModal = ({ onCloseModal, filters }) => {
  const [search, setSearch] = useState('');
  const [activeTab, setActiveTab] = useState('regionNodes');
  const [graphFilters, setGraphFilters] = useRecoilState(graphFiltersRecoil);
  const [chosenFilters, setChosenFilters] = useState(graphFilters);

  const addFilter = (activeTab, filter) => {
    const checkIfAdded = chosenFilters[activeTab].find((el) => el === filter);
    if (checkIfAdded != null) {
      const filteredArray = chosenFilters[activeTab].filter((el) => el !== filter);
      setChosenFilters({ ...chosenFilters, [activeTab]: filteredArray });
    } else {
      setChosenFilters({ ...chosenFilters, [activeTab]: [...chosenFilters[activeTab], filter] });
    }
  };

  const confirmFilters = () => {
    setGraphFilters(chosenFilters);
    onCloseModal();
  };

  const clearFilters = () => {
    setChosenFilters({ regionNodes: [], businessTypes: [] });
  };

  return (
    <div className="absolute top-12 left-0 z-[100] flex space-x-4">
      <div
        className='background w-[566px] h-fit rounded-lg'
      >
      <div className="w-full flex justify-between py-4 px-6 z-20 border-b border-tertiary">
        <h1 className="font-semibold text-base text-left title">Filter</h1>
        <MdClose
          onClick={onCloseModal}
          className="cursor-pointer text-xl my-auto icon"
        />
      </div>
      <div className="h-full flex flex-col justify-between overflow-y-scroll">
        <div>
          <GraphFiltersTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <div className="border-b border-tertiary py-4 px-6">
            <GraphFiltersSearch
              search={search}
              setSearch={setSearch}
            />
            <GraphFiltersCheckboxes
              activeTab={activeTab}
              chosenFilters={chosenFilters}
              setChosenFilters={setChosenFilters}
              search={search}
              filters={filters}
            />
          </div>
        </div>
        <GraphFiltersButtons
          clearFilters={clearFilters}
          confirmFilters={confirmFilters}
        />
      </div>
      </div>
      <div
        className={`${
          chosenFilters.businessTypes.length == 0 && chosenFilters.regionNodes.length == 0 ? 
          'hidden' : 'background w-[566px] h-fit rounded-lg'}`}
      >
        <GraphFiltersChoices
          addFilter={addFilter}
          chosenFilters={chosenFilters}
          filters={filters}
        />
      </div>
    </div>
  );
};

GraphFiltersModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

export default GraphFiltersModal;
