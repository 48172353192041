import React from 'react';
import PropTypes from 'prop-types';
import formatNumber from 'utils/KubenDashboard/formatNumber';

const DashboardChartLegend = ({
  chartData,
  graphDefinition,
  isVisible,
  activeTitles,
  setActiveTitles,
  onDataItemHover,
}) => {
  const toggleTitle = (titleId) => {
    const tmpTitles = [...activeTitles];
    if (activeTitles.includes(titleId)) {
      tmpTitles.splice(tmpTitles.indexOf(titleId), 1);
    } else {
      tmpTitles.push(titleId);
    }
    setActiveTitles(tmpTitles);
  };

  return (
    <div
      className={`flex-col transition-all duration-300 ease-in-out pr-2
     ${isVisible ? 'w-full flex overflow-y-scroll' : 'w-0 overflow-hidden'}`}
    >
      <table className="border-separate border-spacing-2">
        <thead>
          <tr className="font-bold p-4">
            <th className="text-left title">{graphDefinition.title}</th>
            {chartData.titles?.map((title, idx) => (
              <th
                style={{
                  backgroundColor: title.color,
                }}
                key={idx}
                onClick={() => toggleTitle(title.id)}
                className={`${
                  activeTitles.includes(title.id) ? 'opacity-100 border border-tertiary drop-shadow' : 'opacity-50'
                }
                 rounded-xl hover:shadow hover:scale-105 transition-all duration-150 ease-out w-12`}
              ></th>
            ))}
          </tr>
        </thead>
        <tbody>
          {chartData.data.map((d, idx) => (
            <tr
              className="text-center opacity-50 hover:opacity-100"
              key={idx}
              onMouseEnter={() => onDataItemHover(d.name)}
              onMouseLeave={() => onDataItemHover(null)}
            >
              <td className="text-left whitespace-nowrap subtitle">{d.name}</td>
              {chartData.titles.map((title, idx) => (
                <td
                  className={`whitespace-nowrap text-right subtitle
                   ${activeTitles.includes(title.id) ? 'opacity-100' : 'opacity-10'}
                   `}
                  key={idx}
                >
                  {d[title.id] ? `${formatNumber(d[title.id])} ${chartData.units}` : '-'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

DashboardChartLegend.propTypes = {
  graphDefinition: PropTypes.any.isRequired,
  chartData: PropTypes.any.isRequired,
  isVisible: PropTypes.bool.isRequired,
  activeTitles: PropTypes.array.isRequired,
  setActiveTitles: PropTypes.func.isRequired,
  onDataItemHover: PropTypes.func.isRequired,
};

export default DashboardChartLegend;
