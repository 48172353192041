import React from 'react';
import { Route, Switch } from 'react-router-dom';

import RegionsPage from '../../../pages/KubenAdmin/RegionsPage/RegionsPage';
import RegionAddUnitPage from '../../../pages/KubenAdmin/RegionsPage/RegionAddUnitPage';
import RegionEditUnitPage from '../../../pages/KubenAdmin/RegionsPage/RegionEditUnitPage';
import RegionsPageDetails from '../../../pages/KubenAdmin/RegionsPage/RegionsPageDetails';
import ReportPage from '../../../pages/KubenAdmin/ReportPage/ReportPage';
import ReportEditPage from '../../../pages/KubenAdmin/ReportPage/ReportEditPage';
import TemplatesPage from '../../../pages/KubenAdmin/TemplatesPage/TemplatesPage';
import TemplatesEditPage from '../../../pages/KubenAdmin/TemplatesPage/TemplatesEditPage';
import PersonPageDetails from '../../../pages/KubenAdmin/PersonPageDetails';
import UsersPageDetails from '../../../pages/KubenAdmin/UsersPageDetails';
import UsersInvitePage from '../../../pages/KubenAdmin/UsersInvitePage';
import UsersPage from '../../../pages/KubenAdmin/UsersPage';
import FilesPage from '../../../pages/KubenAdmin/FilesPage';
import PersonPage from '../../../pages/KubenAdmin/PersonPage';
import BusinessValuesPage from '../../../pages/KubenAdmin/BusinessValuesPage/BusinessValuesPage';
import BusinessValuesEditStairPage from '../../../pages/KubenAdmin/BusinessValuesPage/BusinessValuesEditStairPage';
import BusinessValuesEditPage from 'pages/KubenAdmin/BusinessValuesPage/BusinessValuesEditPage';

const AppAdminLayout = () => {

  return (
    <div
      className='mx-auto mt-5'
    >
      <Switch>
        <Route
          exact
          path="/admin"
          component={UsersPage}
        />
        <Route
          exact
          path="/admin/users"
          component={UsersPage}
        />
        <Route
          exact
          path="/admin/register"
          component={UsersInvitePage}
        />
        <Route
          exact
          path="/admin/users/:id"
          component={UsersPageDetails}
        />
        <Route
          exact
          path="/admin/persons"
          component={PersonPage}
        />
        <Route
          exact
          path="/admin/persons/:id"
          component={PersonPageDetails}
        />
        <Route
          exact
          path="/admin/regions"
          component={RegionsPage}
        />
        <Route
          exact
          path="/admin/regions/:id"
          component={RegionsPageDetails}
        />
        <Route
          exact
          path="/admin/regions/:id/edit"
          component={RegionEditUnitPage}
        />
        <Route
          exact
          path="/admin/regions/:id/add"
          component={RegionAddUnitPage}
        />
        <Route
          exact
          path="/admin/templates"
          component={TemplatesPage}
        />
        <Route
          exact
          path="/admin/templates/:id"
          component={TemplatesEditPage}
        />
        <Route
          exact
          path="/admin/reports"
          component={ReportPage}
        />
        <Route
          exact
          path="/admin/reports/:id"
          component={ReportEditPage}
        />
        <Route
          exact
          path="/admin/files"
          component={FilesPage}
        />
        <Route
          exact
          path="/admin/businessvalues"
          component={BusinessValuesPage}
        />
        <Route
          exact
          path="/admin/businessvalues/staircase/:id"
          component={BusinessValuesEditStairPage}
        />
        <Route
          exact
          path="/admin/businessvalues/:id"
          component={BusinessValuesEditPage}
        />
      </Switch>
    </div>
  );
};

export default AppAdminLayout;
