import React from 'react';

const AppSwitcherButton = () => {
  return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1440_330"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="18"
          height="18"
        >
          <rect
            width="18"
            height="18"
            fill="currentColor"
          />
        </mask>
        <g mask="url(#mask0_1440_330)">
          <path
            d="M3.75 8.25C3.3375 8.25 2.98438 8.10313 2.69063 7.80937C2.39688 7.51562 2.25 7.1625 
          2.25 6.75V3.75C2.25 3.3375 2.39688 2.98438 2.69063 2.69063C2.98438 2.39688 3.3375 2.25 
          3.75 2.25H6.75C7.1625 2.25 7.51562 2.39688 7.80937 2.69063C8.10313 2.98438 8.25 3.3375 8.25 
          3.75V6.75C8.25 7.1625 8.10313 7.51562 7.80937 7.80937C7.51562 8.10313 7.1625 8.25 6.75 8.25H3.75ZM3.75 
          15.75C3.3375 15.75 2.98438 15.6031 2.69063 15.3094C2.39688 15.0156 2.25 14.6625 2.25 14.25V11.25C2.25 10.8375
          2.39688 10.4844 2.69063 10.1906C2.98438 9.89688 3.3375 9.75 3.75 9.75H6.75C7.1625 9.75 7.51562 9.89688 7.80937
          10.1906C8.10313 10.4844 8.25 10.8375 8.25 11.25V14.25C8.25 14.6625 8.10313 15.0156 7.80937 15.3094C7.51562 
          15.6031 7.1625 15.75 6.75 15.75H3.75ZM11.25 8.25C10.8375 8.25 10.4844 8.10313 10.1906 7.80937C9.89688 7.51562 
          9.75 7.1625 9.75 6.75V3.75C9.75 3.3375 9.89688 2.98438 10.1906 2.69063C10.4844 2.39688 10.8375 2.25 11.25 
          2.25H14.25C14.6625 2.25 15.0156 2.39688 15.3094 2.69063C15.6031 2.98438 15.75 3.3375 15.75 3.75V6.75C15.75 
          7.1625 15.6031 7.51562 15.3094 7.80937C15.0156 8.10313 14.6625 8.25 14.25 8.25H11.25ZM11.25 15.75C10.8375 
          15.75 10.4844 15.6031 10.1906 15.3094C9.89688 15.0156 9.75 14.6625 9.75 14.25V11.25C9.75 10.8375 9.89688 
          10.4844 10.1906 10.1906C10.4844 9.89688 10.8375 9.75 11.25 9.75H14.25C14.6625 9.75 15.0156 9.89688 15.3094 
          10.1906C15.6031 10.4844 15.75 10.8375 15.75 11.25V14.25C15.75 14.6625 15.6031 15.0156 15.3094 15.3094C15.0156 
          15.6031 14.6625 15.75 14.25 15.75H11.25ZM3.75 6.75H6.75V3.75H3.75V6.75ZM11.25 
          6.75H14.25V3.75H11.25V6.75ZM11.25 14.25H14.25V11.25H11.25V14.25ZM3.75 14.25H6.75V11.25H3.75V14.25Z"
            fill="currentColor"
          />
        </g>
      </svg>
  );
};

export default AppSwitcherButton;
