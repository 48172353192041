import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UIButtonSubmit from '../../../global/UIButtons/UIButtonSubmit';
import UIModalWrapper from '../../../global/UIModals/UIModalWrapper';
import UIDeleteButton from '../../UIAssessments/UIDeleteButton';

const QuestionsCommentsModal = ({ comment, handleClick, isOpen, onClose, questionId, isReadonly = false }) => {
  const [newComment, setNewComment] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setNewComment(comment ? comment : '');
  }, [isOpen]);

  const handleClickDeleteComment = () => {
    setNewComment('');
    handleClick(questionId, 'Comment', null);
  };

  const handleClickAddComment = () => {
    onClose();
    handleClick(questionId, 'Comment', newComment);
  };

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const onCloseModal = () => {
    setNewComment('');
    onClose();
  };

  return (
    <UIModalWrapper
      height="h-fit"
      additionalPadding="p-0 "
      variant="center"
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <div className="w-full text-left pl-10 pt-10">
        <h2 className="text-20px font-bold text">
          {t('QuestionsPage.commentModalInputTitle')}
          <span className="font-normal ml-1">{comment ? '(1)' : '(0)'}</span>
        </h2>
        <p className="text-base title">
          {comment
            ? t('QuestionsPage.descriptionModifyCommentModalText')
            : t('QuestionsPage.descriptionAddCommentModalText')}
        </p>
      </div>
      <div>
        <textarea
          className={
            'border border-tertiary w-[846px] min-h-[150px]' +
            ' rounded-md mt-2 mb-5 pl-2 py-1 pr-4 text-sm hover:outline-none focus:outline-none'
          }
          onChange={(e) => handleCommentChange(e)}
          value={newComment}
        />
        <div
          className={
            'flex flex-row justify-end items-center px-10 w-[926px] py-4 space-x-5'
          }
        >
          {comment && !isReadonly && (
            <div className="flex flex-grow items-start">
              <UIDeleteButton
                onDelete={handleClickDeleteComment}
                name={t('QuestionsPage.deleteCommentModalButtonText')}
              />
            </div>
          )}
          <button
            onClick={onCloseModal}
            className="px-8 text-sm h-8 button-underlined underline"
          >
            {t('QuestionsPage.closeCommentModalButtonText')}
          </button>
          <UIButtonSubmit
            name={
              comment ? t('QuestionsPage.modifyCommentModalButtonText') : t('QuestionsPage.addCommentModalButtonText')
            }
            isIcon={false}
            assessments={true}
            enabled={!isReadonly && newComment?.length ? true : false}
            fixedWidth={false}
            onSubmit={handleClickAddComment}
          />
        </div>
      </div>
    </UIModalWrapper>
  );
};

QuestionsCommentsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool,
  questionId: PropTypes.string,
  comment: PropTypes.string,
};

export default QuestionsCommentsModal;
