import actions from 'api/graph';
import mutation from '../useQueryWrapper';

const useMutateFetchDashboardGraphs = (chosenRegionId, businessUnitIds, businessTypeIds, startDate, endDate) => {
 return mutation.useMutationWrapper(
  async () => {
    const res = await actions.fetchDashboardGraphs(
      chosenRegionId,
      businessUnitIds,
      businessTypeIds,
      startDate,
      endDate,
    );
    return res.data;
  }
 );};

export default useMutateFetchDashboardGraphs;
