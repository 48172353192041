import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ReportsPage from '../../../pages/KubenReports/ReportsPage';

const AppReportLayout = () => {

  return (
    <Switch>
      <Route
        exact
        path="/reports"
        component={ReportsPage}
      />
    </Switch>
  );
};

export default AppReportLayout;
