import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { currentApp } from 'state/global/currentApp';
import { colorThemes, currentColorTheme, currentUser as currentUserRecoil } from 'state/global/currentUser';
import { fetchCurrentUser } from 'hooks/api/account/useFetchCurrentUser';
import useSignIn from 'hooks/api/signIn';
import useFetchThemeColors from 'hooks/api/user/useFetchColorThemes';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import ArrowLoader from 'components/icons/loader/ArrowLoader';

import ensolutionLogo from 'assets/global/ensolution-light.png';

const PageLogin = () => {
  const history = useHistory();
  const resetCurrentUsingApp = useResetRecoilState(currentApp);
  const setCurrentApp = useSetRecoilState(currentApp);
  const currentUser = useRecoilValue(currentUserRecoil);
  const allThemes = useRecoilValue(colorThemes);
  const setColorTheme = useSetRecoilState(currentColorTheme);
  const [email, setEmail] = useState('');
  const {
    isLoading: isCurrentUserLoading,
    isError: isCurrentUserError,
    refetch: refetchFetchCurrentUser,
  } = fetchCurrentUser();
  const {refetch: refetchThemes } = useFetchThemeColors();
  const { 
    isLoading: isSignInLoading,
    isSuccess: isSignInSuccess,
    isError: isSignInError,
    mutate: mutateSignIn,
    error: signInError
  } = useSignIn({ onSuccess: () => onLoginSuccess()});

  const isLoading = (isSignInLoading|| isCurrentUserLoading);
  const isError = (isSignInError || isCurrentUserError);

  const onProviderLogin = () => {
    mutateSignIn(email);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onProviderLogin();
    }
  };

  const onLoginSuccess = () => {
    refetchFetchCurrentUser();
    refetchThemes();
  };

  useEffect(() => {
    resetCurrentUsingApp();
    document.title = 'Logga in';
  }, []);

  const goToPage = (path, name, title) => {
    setCurrentApp(name);
    document.title = title;
    history.push(path);
  };

  useEffect(() => {
    if (!currentUser || !allThemes) {
      return;
    }

    const theme = allThemes.find(theme => theme.id == currentUser.theme);
    setColorTheme(theme);

    if (currentUser.hasAccessToLandingPage) {
      goToPage('/', '', 'Kuben');
    } else if (currentUser.hasAccessToAssessments) {
      goToPage('/assessments/', 'assessment', 'Kuben Bedömningar');
    } else if (currentUser.hasAccessToReports) {
      goToPage('/reports/', 'report', 'Kuben Rapporter');
    } else if (currentUser.hasAccessToAnalytics) {
      goToPage('/analytics/', 'analytics', 'Kuben Analytics');
    } else if (currentUser.hasAccessToAdmin) {
      if (currentUser.hasAccessToAdminUser) {
        goToPage('/admin/users', 'admin', 'Kuben Admin');
      } else if (currentUser.hasAccessToAdminPersons) {
        goToPage('/admin/persons', 'admin', 'Kuben Admin');
      } else if (currentUser.hasAccessToAdminRegion) {
        goToPage('/admin/regions', 'admin', 'Kuben Admin');
      } else if (currentUser.hasAccessToAdminTemplates) {
        goToPage('/admin/templates', 'admin', 'Kuben Admin');
      } else if (currentUser.hasAccessToAdminReports) {
        goToPage('/admin/reports', 'admin', 'Kuben Admin');
      } else if (currentUser.hasAccessToAdminFiles) {
        goToPage('/admin/files', 'admin', 'Kuben Admin');
      }
    }
  }, [currentUser, allThemes]);

  return (
    <div
      className='2xl:p-8 h-screen w-screen background-secondary'
    >
    <div className="flex flex-col relative content-center h-full w-full background-primary
     title counter font-semibold 2xl:rounded-3xl">
      <div className="flex items-end justify-between w-full self-start text-secondary absolute px-14 pt-14">
        <img 
          className='w-48'
        src={ensolutionLogo} />
        <button
          onClick={() => window.open(`http://ensolution.se/`, '_blank')}
          className="text-md font-semibold cursor-pointer
          sm:hidden
          transition-all
          border-secondary
          border px-4 py-2 rounded-full
          flex items-center space-x-2 hover:scale-105"
        >
          <label>
            Gå till ensolution.se
          </label>
          <div
            className='h-3'
          >
            <ArrowLoader/>
          </div>
        </button>
      </div>
          {isError ? 
          <label>
            {signInError}
          </label>
          :
          isLoading || isSignInSuccess ? 
          <div
            className='background-secondary h-72 w-72 rounded-2xl relative
            absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'
          >
            <UILoadingModal
              customMessage='Inloggning sker i nytt fönster'
              messageStyle='text-secondary'
            />
          </div>
          :
      <div
            className='my-auto mx-auto max-w-xs w-full space-y-8 text-left'
      >
        <div
          className='flex flex-col'
        >
          <h1
            className='text-3xl 2xl:text-4xl text-white whitespace-nowrap'
          >
            Välkommen till Kuben
            </h1>
            <h2
              className='text-lg 2xl:text-xl text-secondary font-normal'
            >
              Logga in med din e-post
            </h2>
        </div>
        <form className="flex flex-col justify-center items-center space-y-4">
            <input
              type="email"
              autoComplete="username"
              name='email'
              placeholder="E-post"
              className={`rounded-lg 2xl:rounded-xl  
                 py-3 px-3 w-full
                 bg-white 
                 text-primary
                placeholder-inherit
                transition-all duration-300 ease-in-out h-12 2xl:h-14
              outline-none focus:border-black active:border-black`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
            >
            </input>
            <input
              name='password'
              type='password'
              autoComplete="current-password"
              hidden
            />
            <button
              autoFocus
              className={`
                flex items-center justify-between 
                px-3 py-3
                rounded-lg 2xl:rounded-xl mx-auto py-3 background-accent text-primary  transition-all
                h-12
                2xl:h-14
                w-full
                transition-all
                duration-150
                whitespace-nowrap
                group
              `}
              onClick={onProviderLogin}
            >
              <label
                className='text-center w-full transition-all cursor-inherit'
              >
                Logga in
              </label>
            </button>
        </form>
      </div>
      }
        <div
          className='grid grid-cols-2 gap-2 h-28 w-28 2xl:h-36 2xl:w-36 absolute left-14 bottom-14'
        >
          <ArrowLoader
            className='text-secondary'
          />
          <ArrowLoader
            className='text-accent'
          />
          <ArrowLoader
            className='text-accent'
          />
          <ArrowLoader
            className='text-secondary'
          />
        </div>
        <a
        href='http://kubens-help.azurewebsites.net/'
        target='_blank'
        className='text-sm text-secondary underline absolute right-14 bottom-14 sm:hidden'
        rel="noreferrer"
        >Behöver du hjälp?</a>
      </div>
    </div>
  );
};

export default PageLogin;
