import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BsArrowLeftShort } from 'react-icons/bs';

const UIBackButton = ({ title, onSubmit, showIcon = true }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <button
      onClick={onSubmit != null ? () => onSubmit() : () => history.goBack()}
      className={`flex w-full justify-center flex-row text-center button-primary
      items-center go-back rounded-20px py-1
      ${showIcon === true ? 'pl-3 pr-6' : ''}`}
    >
      {showIcon && <BsArrowLeftShort className="text-3xl mr-2" />}
      {title || t('UIBackButton.goBackText')}
    </button>
  );
};

UIBackButton.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  showIcon: PropTypes.bool,
};

export default UIBackButton;
