import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import { useRecoilState } from 'recoil';
import { MdModeEditOutline } from 'react-icons/md';

import useUpdateTags from 'hooks/api/person/useUpdateTags';
import useFetchTags from 'hooks/api/person/useFetchTags';
import { usersSetSort } from 'state/KubenAnalytics/users/usersSorts';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import AppListViewSortArrows from './AppListViewSortArrows';
import AppListViewDataTagsModal from './AppListViewDataTagsModal';

const AppListviewDataTable = ({ data, columns, hasAccessToAssessment, refetchUsers, isRefetchLoading }) => {
  const [sorting, setSorting] = useRecoilState(usersSetSort);
  const [toggleTagsModal, setToggleTagsModal] = useState(false);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });
  const [selectedPerson, setSelectedPerson] = useState();
  const { isLoading: isUpdateTagsLoading, isError: isUpdateTagsError, isSuccess, mutate } = useUpdateTags();
  const { isLoading: isRefetchTagsLoading, isError: isRefetchTagsError, refetch } = useFetchTags();
  const isLoading = (isUpdateTagsLoading || isRefetchTagsLoading || isRefetchLoading);

  const openTagsModal = (selectedPerson) => {
    setToggleTagsModal(true);
    setSelectedPerson(selectedPerson);
  };

  const onUpdateTags = (body) => {
    mutate(body);
    setToggleTagsModal(false);
  };

  useEffect(() => {
    refetchUsers();
    refetch();
  }, [isSuccess]);


  if (isUpdateTagsError || isRefetchTagsError) {
    return <div>Error...</div>;
  }

  return (
    <>
    {isLoading && 
      <UILoadingModal customMessage="Samlar data från ditt urval.." />
    }
    <table
      {...getTableProps()}
      className="rounded overflow-scroll h-8/10 w-full"
    >
      <thead>
        {headerGroups.map((headerGroup, idx) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            key={`cell-${idx}`}
          >
            {headerGroup.headers.map((column, i) => (
              <th
                {...column.getHeaderProps({
                  style: { minWidth: column.minWidth },
                })}
                key={`heading-${i}-${idx}`}
                onClick={() => setSorting(column.id)}
                className={'t-head title counter text-sm font-normal ' + 'leading-none py-3 cursor-pointer border-l'}
              >
                <div className="relative inline-block">
                  {column.render('Header')}
                  <AppListViewSortArrows
                    field={column.id}
                    activeField={sorting.field}
                    type={sorting.type}
                  />
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, idx) => {
          prepareRow(row);
          return (
              <tr
                key={`row-${idx}`}
                {...row.getRowProps()}
              >
                {row.cells.map((cell, i) => {
                  if (i === 0) {
                    return (
                      <td
                        key={`cell-${i}-${idx}`}
                        {...cell.getCellProps()}
                        className={`text-xs font-normal py-3
                        ${
                          hasAccessToAssessment ? 'underline text-blue-700 cursor-pointer' : 'no-underline text-black'
                        }`}
                        style={{
                          backgroundColor: idx % 2 === 0 ? 'hsl(228, 24%, 96%)' : 'hsl(0, 0%, 100%)',
                        }}
                      >
                        {hasAccessToAssessment ? (
                          <a
                            href={`/assessments/choose-assessments/${rows[idx]?.original?.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {cell.render('Cell')}
                          </a>
                        ) : (
                          <div>{cell.render('Cell')}</div>
                        )}
                      </td>
                    );
                  }
                  if (cell.column.id === 'Tag') {
                    return (
                      <td
                        key={`cell-${i}-${idx}`}
                        {...cell.getCellProps()}
                        className={`text-xs font-normal py-3`}
                        style={{
                          backgroundColor: idx % 2 === 0 ? 'hsl(228, 24%, 96%)' : 'hsl(0, 0%, 100%)',
                        }}
                      >
                        <div className="flex justify-between px-2">
                          <p>{cell.render('Cell')}</p>
                          <button
                            className="ml-3 opacity-70"
                            onClick={() => openTagsModal(rows[idx].original)}
                          >
                            <MdModeEditOutline className="text-lg" />
                          </button>
                        </div>
                      </td>
                    );
                  }
                  return (
                    <td
                      key={`cell-${i}-${idx}`}
                      {...cell.getCellProps()}
                      className={`text-xs font-normal py-3
                        ${hasAccessToAssessment ? 'underline title accent cursor-pointer' : 'no-underline title'}`}
                      style={{
                        backgroundColor: idx % 2 === 0 ? 'hsl(228, 24%, 96%)' : 'hsl(0, 0%, 100%)',
                      }}
                    >
                      {hasAccessToAssessment ? (
                        <a
                          href={`/assessments/choose-assessments/${rows[idx]?.original?.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {cell.render('Cell')}
                        </a>
                      ) : (
                        <div>{cell.render('Cell')}</div>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {toggleTagsModal && (
        <AppListViewDataTagsModal
          onClose={() => setToggleTagsModal(false)}
          selectedPerson={selectedPerson}
          onUpdateTags={onUpdateTags}
        />
      )}
    </>
  );
};

AppListviewDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  hasAccessToAssessment: PropTypes.bool,
  isRefetchLoading: PropTypes.bool,
  refetchUsers: PropTypes.func,
};

export default AppListviewDataTable;
