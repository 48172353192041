import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { GrFormClose } from 'react-icons/gr';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import closeElementFunction from 'utils/global/closeElementFunction';

const UISaveModal = ({
  closeModal,
  onSave,
  name,
  setName,
  toggled,
  toggleDropdown,
  choose,
  changeChoose,
  isErrorSelection,
  isErrorUsers,
  isLoading,
}) => {
  const wrapperRef = useRef();
  closeElementFunction(wrapperRef, closeModal);

  return (
      <div 
      ref={wrapperRef}
      className="p-5 border h-48 w-102 rounded-lg bg-white border-tertiary
      absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20">
        <div className="w-full h-full relative flex flex-col items-center justify-between">
          {isErrorSelection ||
            (isErrorUsers && (
              <div
                className={
                  'absolute flex items-center font-semibold justify-center text-xl error background-primary ' +
                  'w-full h-full'
                }
              >
                Error occurred
              </div>
            ))}
          <p className="font-semibold text-2xl text-primary">
            {isLoading ? 'Vänligen vänta...' : 'Spara resultatet'}
          </p>
          <div
            onClick={closeModal}
            className="absolute top-0 right-0 cursor-pointer"
          >
            <GrFormClose className="w-6 h-6" />
          </div>
          <div className="flex justify-between w-full items-center input-accent">
            <input
              className="border rounded-md p-2"
              style={{ flexGrow: 1 }}
              required
              value={name}
              placeholder="Ange namn"
              onChange={(e) => setName(e.target.value)}
            />
            <div className="ml-2 relative">
              <button
                className={
                  'h-10 background-secondary title text-sm font-semibold pl-4 pr-2 xl:pl-4 ' +
                  'rounded-md text-left flex lg:pl-2 shadow items-center justify-between ' +
                  `${toggled && 'rounded-b-none border-b border-tertiary-add'} `
                }
                onClick={() => toggleDropdown()}
              >
                {choose}
                {!toggled ? <FiChevronDown className="h-6 w-6 pr-1" /> : <FiChevronUp className="h-6 w-6 pr-1" />}
              </button>
              <div
                className={
                  `${toggled ? 'h-10 rounded-t-none' : 'h-0'} ` +
                  `duration-300 overflow-hidden absolute top-10 left-0 z-10 w-full ` +
                  'rounded-md text-left flex lg:pl-2 shadow items-center px-4 background-secondary'
                }
              >
                {choose === 'Spara som urval' ? (
                  <button
                    onClick={() => changeChoose('Spara som lista')}
                    className="font-semibold text-sm whitespace-nowrap"
                  >
                    Spara som lista
                  </button>
                ) : (
                  <button
                    onClick={() => changeChoose('Spara som urval')}
                    className="font-semibold text-sm whitespace-nowrap"
                  >
                    Spara som urval
                  </button>
                )}
              </div>
            </div>
          </div>
          <UIButtonSubmit
            name="Spara och fortsätt"
            value={name}
            onSubmit={onSave}
            enabled={name.length > 0}
          />
        </div>
      </div>
  );
};

UISaveModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  choose: PropTypes.string.isRequired,
  changeChoose: PropTypes.func.isRequired,
  toggled: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  isErrorSelection: PropTypes.bool.isRequired,
  isErrorUsers: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default UISaveModal;
