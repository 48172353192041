import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { isSameDay, subMonths, startOfMonth, endOfMonth, addMonths } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import { sv } from 'react-date-range/dist/locale';
import { useRecoilState } from 'recoil';
import { graphPeriodFilter } from 'state/KubenDashboard/graphFilters';

const GraphPeriodModal = ({ onCloseModal }) => {
  const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' };
  const [chosenDate, setChosenDate] = useRecoilState(graphPeriodFilter);
  const [date, setDate] = useState([
    {
      startDate: chosenDate.startDate,
      endDate: chosenDate.endDate,
      key: 'selection',
    },
  ]);

  const onSavePeriod = () => {
    setChosenDate({
      startDate: date[0].startDate,
      endDate: date[0].endDate,
    });
    onCloseModal();
  };

  return (
    <div
      className="absolute w-fit top-12 right-0 z-[100]
     background rounded-lg p-2"
    >
      <DateRangePicker
        className="h-[420px]"
        onChange={(item) => setDate([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        locale={sv}
        months={1}
        ranges={date}
        inputRanges={[]}
        rangeColors={['hsl(0, 0%, 12%)']}
        direction="horizontal"
        staticRanges={[
          {
            label: 'Senaste 12 månader',
            range: () => ({
              startDate: startOfMonth(subMonths(new Date(), 12)),
              endDate: endOfMonth(new Date()),
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
              );
            },
          },
          {
            label: 'Senaste 6 månader',
            range: () => ({
              startDate: startOfMonth(subMonths(new Date(), 5)),
              endDate: endOfMonth(new Date()),
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
              );
            },
          },
          {
            label: 'Senaste 3 månader',
            range: () => ({
              startDate: startOfMonth(subMonths(new Date(), 2)),
              endDate: endOfMonth(new Date()),
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
              );
            },
          },
          {
            label: 'Den här månaden',
            range: () => ({
              startDate: startOfMonth(new Date()),
              endDate: endOfMonth(new Date()),
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
              );
            },
          },
          {
            label: 'Kommande 3 månader',
            range: () => ({
              startDate: startOfMonth(new Date()),
              endDate: addMonths(endOfMonth(new Date()), 2),
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
              );
            },
          },
          {
            label: 'Kommande 6 månader',
            range: () => ({
              startDate: startOfMonth(new Date()),
              endDate: addMonths(endOfMonth(new Date()), 5),
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
              );
            },
          },
          {
            label: 'Kommande 12 månader',
            range: () => ({
              startDate: startOfMonth(new Date()),
              endDate: addMonths(startOfMonth(new Date()), 12),
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
              );
            },
          },
        ]}
      />
      <div className="border-t flex p-5 text-base">
        <div className="w-[70%] my-auto">
          {new Date(date[0].startDate).toLocaleDateString('sv-SE', dateOptions)} -{' '}
          {new Date(date[0].endDate).toLocaleDateString('sv-SE', dateOptions)}
        </div>
        <div className="flex justify-between items-center w-[30%]">
          <button
            className="hover:underline transition-all title"
            onClick={onCloseModal}
          >
            Avbryt
          </button>
          <button
            onClick={onSavePeriod}
            className="rounded-[10px] button-primary py-1 px-4"
          >
            Tillämpa
          </button>
        </div>
      </div>
    </div>
  );
};

GraphPeriodModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
};

export default GraphPeriodModal;
