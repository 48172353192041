import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AiOutlineClockCircle } from 'react-icons/ai';

import summaryIcon from 'assets/KubenAssessments/kuben_summary_icon.svg';

const SummaryEstimatedCard = ({
  title,
  variant = 'Person',
  isHidden,
  doesContainJudged,
  desiredResult,
  judgedResult,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between xl:pl-6 xl:pr-16 px-4 pb-5 pt-7 last:rounded-b-[14px] summary-estimated-card">
      <div className="flex">
        {variant == 'Person' ? (
          <img
            className="w-16 h-16 mr-3"
            src={summaryIcon}
          />
        ) : (
          <div
            className="background rounded-10px shadow-summaryPill h-16 w-16 flex items-center justify-center
                          mr-3 xl:mr-6"
          >
            <AiOutlineClockCircle className="text-[28px]" />
          </div>
        )}
        <div className="flex flex-col text-left justify-center md:pr-2">
          <h2 className="text-22px font-semibold leading-none title">{title}</h2>
        </div>
      </div>
      {!isHidden && (
        <div className="flex h-[62px] shadow-summaryPill">
          <div
            className={
              'flex items-center justify-center min-w-[128px] background px-6 ' +
              'rounded-10px border border-tertiary'
            }
          >
            {doesContainJudged && (
              <div className="flex flex-col">
                <span className="text-sm">{t('SummaryPage.desirableAnswerLabel')}</span>
                <span className="text-22px title font-semibold leading-5">{desiredResult}</span>
              </div>
            )}
            <div className="flex flex-col ml-6">
              <span className="text-sm">{t('SummaryPage.currentAnswerLabel')}</span>
              <span className="text-22px title font-semibold leading-5">{judgedResult}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SummaryEstimatedCard.propTypes = {
  title: PropTypes.string,
  isHidden: PropTypes.bool,
  doesContainJudged: PropTypes.bool,
  desiredResult: PropTypes.string,
  judgedResult: PropTypes.string,
  variant: PropTypes.oneOf(['User', 'Person']),
};

export default SummaryEstimatedCard;
