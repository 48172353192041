import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { currentUser, currentUserNavigationState } from 'state/global/currentUser';
import { fetchCurrentUser } from 'hooks/api/account/useFetchCurrentUser';
import useFetchAnalyticsModels from 'hooks/api/analytics/useFetchAnalyticsModels';

import DashboardElementsHeader from 'components/global/DashboardElements/DashboardElementsHeader';
import DashboardElementsLinkCard from 'components/global/DashboardElements/DashboardElementsLinkCard';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIAnalyticsRegionDropdown from 'components/KubenAnalytics/UIAnalytics/UIAnalyticsRegionDropdown';

import AnayliticsOverviewGrapImage from 'components/icons/KubenAnalytics/AnayliticsOverviewGrapImage';
import AnalyticsPrediction from 'components/icons/StartPage/pageImages/AnalyticsPredictionImage';


const PageOverview = () => {
  const history = useHistory();
  const { isLoading: isSelectionLoading, isError: isSelectionError } = fetchCurrentUser();
  const setNavigationState = useSetRecoilState(currentUserNavigationState);
  const resetNavigationState = useResetRecoilState(currentUserNavigationState);
  const { isLoading, isError, data } = useFetchAnalyticsModels();
  const user = useRecoilValue(currentUser);

  useEffect(() => {
    resetNavigationState();
    document.title = 'Kuben Analytics';
  }, []);

  const navigateLineChart = () => {
    setNavigationState({
      navigatePrediction: true,
    });
    history.push('/analytics/filter');
  };

  if (isLoading || isSelectionLoading) {
    return <UILoadingModal isMessage={true} />;
  }

  if (isError || isSelectionError) {
    return <div>Error occurred</div>;
  }

  return (
    <>
      <UIAnalyticsRegionDropdown analyticModels={data} />
    <div className="h-full w-full max-w-[970px] m-[55px] mx-auto">
      <DashboardElementsHeader name={user.name} />
      <div className="flex w-full py-10 space-x-2 h-[80%] md:h-full lg:h-full startsidaTable">
        <div className="w-full space-y-2 text-lg block h-auto primary md:h-[735px]">
          <DashboardElementsLinkCard
            variant="height"
            type="link"
            link="/analytics/filter"
          >
            <div className="transition-all group-hover:scale-105 primary text-4xl my-4 max-w-[210px]">
              <AnayliticsOverviewGrapImage />
            </div>
            {'Gör ett urval'}
          </DashboardElementsLinkCard>
          <div className="grid xl:flex h-1/2 xl:space-x-2 space-y-2 xl:space-y-0">
            <DashboardElementsLinkCard
              variant="width"
              type="card"
              onClick={navigateLineChart}
              link="/analytics/prediction"
            >
              <div className="transition-all group-hover:scale-105 primary text-4xl my-4 max-w-[210px]">
                <AnalyticsPrediction />
              </div>
              {'Gör en prediktion'}
            </DashboardElementsLinkCard>
            <DashboardElementsLinkCard
              variant="width"
              type="link"
              link="/analytics/saved-selection"
            >
              <div className="transition-all group-hover:scale-105 primary text-4xl my-4 max-w-[210px]">
                <AnalyticsPrediction />
              </div>
              {'Gör en uppföljning'}
            </DashboardElementsLinkCard>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default PageOverview;
