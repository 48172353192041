import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import actions from 'api/auth';
import ArrowLoader from 'components/icons/loader/ArrowLoader';

import ensolutionLogo from 'assets/global/ensolution-dark.png';

const PageError = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const errorTypes = [401, 403, 404, 500];
  const errorType = Number.parseInt(history?.location?.pathname?.slice(-3));
  const title = t(`ErrorPage.error${errorType}Title`);
  const description = t(`ErrorPage.error${errorType}Desc`);

  if (!errorTypes.includes(errorType)) {
    history.push('/');
    return <></>;
  }

  return (
    <div
      className='h-screen w-screen background-secondary'
    >
      <div className="flex items-end justify-between w-full self-start text-secondary absolute px-20 pt-20">
        <img 
          className='w-48'
        src={ensolutionLogo} />
        <button
          onClick={() => window.open(`http://ensolution.se/`, '_blank')}
          className="text-md font-semibold font-sans cursor-pointer
          transition-all
          border-primary
          text-primary
          border px-4 py-2 rounded-full
           flex items-center space-x-2 hover:scale-105"
        >
          <label>
            Gå till ensolution.se
          </label>
          <div
            className='h-3'
          >
            <ArrowLoader
              className='text-accent'
            />
          </div>
        </button>
      </div>
      <div
            className='
            absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
            my-auto mx-auto max-w-[385px] w-full 
            space-y-8 
            text-center
             background-secondary rounded-xl p-6'
      >
        <div
          className='flex flex-col space-y-4'
        >
          <h1
            className='text-4xl text-primary'
          >
            {title}
            </h1>
            <h2
              className='text-xl text-primary font-normal'
            >
              {description}
            </h2>
        </div>
        {errorType == 401 ?
        <button
          className='button-primary rounded-xl py-2 px-4 w-1/2'
          onClick={() => actions.logout()}
        >
          {t(`ErrorPage.error401Button`)}
        </button>
        : [500, 404, 403].includes(errorType) ?
          <button
            className='button-primary rounded-xl py-2 px-4 w-1/2'
            onClick={() => history.push('/')}
          >
            {t(`ErrorPage.returnToStart`)}
          </button> :
        <></>
        }
      </div>
      <a
        href='http://kubens-help.azurewebsites.net/'
        target='_blank'
        className='text-sm text-primary underline 
            absolute left-1/2 transform -translate-x-1/2 bottom-14' 
        rel="noreferrer"
        >Behöver du hjälp?</a>
    </div>
  );
};

export default PageError;
