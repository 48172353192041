import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FiAlertTriangle } from 'react-icons/fi';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';

import { cutText } from 'utils/global/cutText';

import QuestionsDisplayModalButton from '../QuestionsPage/QuestionsPageModals/QuestionsDisplayModalButton';
import SummaryTypeHandler from './SummaryTypes/SummaryTypeHandler';

const SummaryBox = ({
  variant,
  header,
  description,
  checkedAnswer,
  answersOptions,
  isAnswerRequired,
  isAnswerBlocked,
  userComments,
  minValue,
  maxValue,
  step,
  isPercentage,
  isEditable,
  onClick,
  questionId,
  isReadonly,
  isDesirableAnswerEnabled,
  onRedirectToQuestionClick,
  isAnsweredExternally,
  isOnlyDefaultAnswers,
  isAnswerIsDefault,
}) => {
  const { t } = useTranslation();
  const [openCard, setOpenCard] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const typeVariants =
    variant == 'Time' ||
    variant == 'Value' ||
    variant == 'EmploymentRate' ||
    variant == 'Frequency' ||
    variant == 'WeeklySchedule';

  const onToggleQuestionCard = () => {
    setOpenCard(!openCard);
  };

  const checkIfAnswered = () => {
    if (!isAnswerRequired) return true;
    if (variant == 'WeeklySchedule' && checkedAnswer.dailySchedules.length > 0) {
      return true;
    }
    if ((variant == 'Date' || variant == 'StartDate' || variant == 'EndDate') && checkedAnswer?.date != null) {
      return true;
    }
    if ((variant == 'SingleAnswer' || variant == 'Unit' || variant == 'Nps') && checkedAnswer?.answerOptionId != null) {
      return true;
    }
    if (variant == 'Multi' && checkedAnswer?.answerOptionIds != null && checkedAnswer?.answerOptionIds?.length > 0) {
      return true;
    }
    if (variant == 'Judged' && checkedAnswer?.desiredOptionId != null && checkedAnswer?.judgedOptionId != null) {
      return true;
    }
    if (
      variant == 'Time' &&
      checkedAnswer?.hours != null &&
      checkedAnswer?.minutes != null &&
      checkedAnswer?.timePeriod != null
    ) {
      return true;
    }
    if (variant == 'Frequency' && checkedAnswer?.count != null && checkedAnswer?.timePeriod != null) {
      return true;
    }
    if ((variant == 'Value' || variant == 'EmploymentRate') && checkedAnswer?.value != null) {
      return true;
    }
    return false;
  };

  if (isOnlyDefaultAnswers && isAnswerIsDefault) return null;

  return (
    <div
      className={
        `${!typeVariants ? 'flex-row' : 'flex-col'} ` +
        `${checkIfAnswered() || variant === 'EndDate' ? '' : 'not-answered'} ` +
        'flex w-full border pl-6 pt-6 pb-5 pr-8 rounded-13px relative mt-3 summary-box content-card'
      }
    >
      <div className="flex flex-col w-2/3 text-left pr-10 md:pr-24 lg:pr-14">
        <div className="md:block flex items-center mb-3 space-x-3">
          <h2
            onClick={() => onRedirectToQuestionClick(questionId)}
            className={`text-2xl title font-semibold ${isReadonly ? '' : 'cursor-pointer'}`}
          >
            {header}
          </h2>
          {(!checkIfAnswered() || isAnswerBlocked) && (
            <div
              className={
                'not-answered-text flex items-center md:ml-0 ml-5 font-semibold ' +
                ' py-1 px-6 max-w-[140px] rounded-[20px] md:mt-4 whitespace-nowrap'
              }
            >
              <FiAlertTriangle />
              <p className="text-sm ml-1">{!checkIfAnswered() ? t('SummaryPage.noAnswer') : 'Felaktigt svar'}</p>
            </div>
          )}
        </div>
        {!openCard && description !== null && (
          <div>
            <p className="text-lg font-normal text-muted">{!showMore ? cutText(description, 155) : description}</p>
            {!showMore && description.length > 155 && (
              <a
                onClick={() => setShowMore(!showMore)}
                className="block underline opacity-100 cursor-pointer button-underlined"
              >
                {t('SummaryPage.showMore')}
              </a>
            )}
            {showMore && description.length > 155 && (
              <a
                onClick={() => setShowMore(!showMore)}
                className="block underline button-underlined opacity-100 cursor-pointer"
              >
                {t('SummaryPage.showLess')}
              </a>
            )}
          </div>
        )}
      </div>
      {!openCard && (
        <div className={`${!typeVariants ? 'w-1/3 items-end' : 'w-full items-start mt-6'} flex flex-col mr-4`}>
          <div
            className={`${!typeVariants ? 'justify-between' : 'absolute top-6 right-12 justify-end'}
                          flex flex-row w-64`}
          >
            <span className={`${!typeVariants ? 'block' : 'hidden'} text-base text font-semibold`}>
              {t('SummaryPage.yourAnswerLabel')}
            </span>
            <QuestionsDisplayModalButton
              title={`Visa kommentar ${userComments != null ? '(1)' : '(0)'}`}
              modalVariant="comments"
              buttonVariant="underline"
              data={userComments}
              summary={true}
              fontNormal={true}
              handleClick={onClick}
              questionId={questionId}
              isReadonly={isReadonly}
              isTransparent={true}
            />
          </div>
          <SummaryTypeHandler
            dropdownItems={answersOptions}
            title={checkedAnswer?.date !== null ? checkedAnswer?.date : 'Inget datum valt'}
            userAnswers={checkedAnswer}
            minValue={minValue}
            maxValue={maxValue}
            step={step}
            questionType={variant}
            handleClick={onClick}
            questionId={questionId}
            isEditable={isEditable}
            isReadonly={isReadonly}
            isPercentage={isPercentage}
            isDesirableAnswerEnabled={isDesirableAnswerEnabled}
            isAnsweredExternally={isAnsweredExternally}
          />
        </div>
      )}
      {!openCard ? (
        <div
          onClick={onToggleQuestionCard}
          className="absolute top-[26px] right-4"
        >
          <MdCheckBoxOutlineBlank className="text-xl checkbox" />
        </div>
      ) : (
        <div
          onClick={onToggleQuestionCard}
          className="absolute top-8 right-4"
        >
          <MdCheckBox className="text-xl checkbox" />
        </div>
      )}
    </div>
  );
};

SummaryBox.propTypes = {
  header: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    'SingleAnswer',
    'Judged',
    'Date',
    'EndDate',
    'StartDate',
    'Time',
    'Percentage',
    'Multi',
    'Value',
    'Nps',
    'Unit',
    'Frequency',
    'EmploymentRate',
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  checkedAnswer: PropTypes.object,
  answersOptions: PropTypes.array.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  isPercentage: PropTypes.bool,
  isAnswerBlocked: PropTypes.bool,
  userComments: PropTypes.string,
  description: PropTypes.string,
  isEditable: PropTypes.bool,
  isAnswerRequired: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  step: PropTypes.number,
  isDesirableAnswerEnabled: PropTypes.bool,
  isOnlyDefaultAnswers: PropTypes.bool,
  isAnswerIsDefault: PropTypes.bool,
  onRedirectToQuestionClick: PropTypes.func.isRequired,
};

export default SummaryBox;
