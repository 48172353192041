import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';

const ChoosePersonNavigation = ({ isEnabled, submit }) => {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-row w-full grow items-center mb-12 justify-end
    transition-all duration-300 ease-in-out ${isEnabled ? 'opacity-100' : 'opacity-0'}`}>
      <div className="w-auto">
        <UIButtonSubmit
          name={t('ChoosePersonPage.nextButtonText')}
          additionalPadding="pl-6 pr-6 py-6"
          onSubmit={() => submit()}
          variant="button"
          enabled={isEnabled}
        />
      </div>
    </div>
  );
};

ChoosePersonNavigation.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
};

export default ChoosePersonNavigation;
