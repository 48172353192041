import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import useSaveRegion from 'hooks/api/region/useSaveRegion';
import useFetchRegionList from 'hooks/api/region/useFetchRegionList';
import {
  regionDetails as regionDetailsRecoil,
  modifyedRegionData as modifyedRegionDataRecoil,
  allRegionsFiltered,
} from 'state/KubenAdmin/regions';
import { searchQuery } from 'state/global/searchQuery';
import tableElements from 'utils/KubenAdmin/RegionsPage/tableElements';
import getUUID from 'utils/KubenAdmin/getUUID';

import UIInput from 'components/global/UICommon/UIInput';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';

import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UIAddElementModal from 'components/KubenAdmin/UIAdmin/UIAddElementModal';
import UITable from 'components/KubenAdmin/UITable/UITable';
import UITableElement from 'components/KubenAdmin/UITable/UITableElement';
import UITableElementWrapper from 'components/KubenAdmin/UITable/UITableElementWrapper';
import UICardWrapper from 'components/KubenAdmin/UIAdmin/UICardWrapper';
const RegionsPage = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useRecoilState(searchQuery);
  const data = {
    id: getUUID(),
    name: '',
    children: [],
    disabled: false,
    rights: {
      canUpdate: true,
      canDelete: true,
    },
    // eslint-disable-next-line quotes
    applicationModules: ['Assessment', 'Report'],
  };
  const { isLoading: isFetchListLoading, isError: isFetchListError, refetch } = useFetchRegionList();
  const { isLoading: isSaveLoading, isError: isSaveError, isSuccess, mutate } = useSaveRegion(data);
  const regionsList = useRecoilValue(allRegionsFiltered);

  const resetRegionDetails = useResetRecoilState(regionDetailsRecoil);
  const resetModifyedRegionData = useResetRecoilState(modifyedRegionDataRecoil);
  const resetSearchQuery = useResetRecoilState(searchQuery);

  useEffect(() => {
    resetSearchQuery('');
  }, []);

  const onSaveRegion = (name) => {
    data.name = name;
    mutate();
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    resetRegionDetails();
    resetModifyedRegionData();
  }, []);

  if (isFetchListLoading || isSaveLoading) {
    return (
      <UILoadingModal
      />
    );
  }

  if (isFetchListError || isSaveError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UITitle
        title="REGIONER"
        isButton={true}
        buttonName="Lägg till region"
        onClick={() => setOpenModal(true)}
      />
      <UICardWrapper title="Sök">
        <UIInput
          type="search"
          placeholder="Sök efter regioner"
          value={search}
          onChange={(text) => setSearch(text)}
        />
      </UICardWrapper>
      <UITable tableElements={tableElements}>
        {regionsList &&
          regionsList.map((el, idx) => (
            <UITableElementWrapper
              key={idx}
              isLink={true}
              link={`regions/${el.id}`}
            >
              <UITableElement
                name={el.name}
                maxLength={150}
              />
              <UITableElement
                name={!el.disabled}
                isBool={true}
              />
            </UITableElementWrapper>
          ))}
      </UITable>
      {openModal && (
        <UIAddElementModal
          onClose={() => setOpenModal(false)}
          isOpen={openModal}
          modalTitle="Lägg till region"
          onSave={(text) => onSaveRegion(text)}
          inputPlaceholder="Region namn"
        />
      )}
    </UIStaticComponentsWrapper>
  );
};

export default RegionsPage;
