import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { currentSelection } from 'state/KubenAnalytics/current/currentSelection';
import { usersState } from 'state/KubenAnalytics/users/users';
import { usersGraphType } from 'state/KubenAnalytics/users/usersGraph';
import { usersFilters } from 'state/KubenAnalytics/users/usersFilters';
import { usersPeriodsType } from 'state/KubenAnalytics/users/usersPeriods';
import { savedUsers } from 'state/KubenAnalytics/saved/savedUsers';
import { savedSelections } from 'state/KubenAnalytics/saved/savedSelections';

import { fetchSavedSelections } from 'hooks/api/analytics/useSavedSelections';
import { fetchSavedUsers } from 'hooks/api/analytics/useSavedUsers';
import useDeleteUserLists from 'hooks/api/analytics/useDeleteUserLists';
import useDeleteSavedSelections from 'hooks/api/analytics/useDeleteSavedSelections';

import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIContainerCard from 'components/global/UICommon/UIContainerCard';
import UISavedSelectionList from 'components/KubenAnalytics/UIAnalytics/UISavedSelectionList';
import UIBackButton from 'components/global/UIButtons/UIBackButton';
import UISpinner from 'components/global/UICommon/UISpinner';

const PageSavedSelection = () => {
  const { t } = useTranslation();
  const savedSelectionsList = useRecoilValue(savedSelections);
  const {
    isLoading: isSelectionLoading,
    isError: isSelectionError,
    refetch: refetchSavedSelections,
  } = fetchSavedSelections();
  const savedUsersList = useRecoilValue(savedUsers);
  const { isLoading: isUserLoading, isError: isUserError, refetch: refetchSavedUsers } = fetchSavedUsers();
  const {
    isLoading: isDeleteSavedSelectionLoading,
    isError: isDeleteSavedSelectionError,
    mutate: mutateDeleteSavedSelections,
  } = useDeleteSavedSelections();
  const {
    isLoading: isDeleteUserListsLoading,
    isError: isDeleteUserListsError,
    mutate: mutateDeleteUserLists,
  } = useDeleteUserLists();
  const resetGraphType = useResetRecoilState(usersGraphType);
  const resetFilters = useResetRecoilState(usersFilters);
  const resetPeriod = useResetRecoilState(usersPeriodsType);
  const resetUsersState = useResetRecoilState(usersState);
  const resetCurrentSelection = useResetRecoilState(currentSelection);
  const isLoading = (isDeleteSavedSelectionLoading || isDeleteUserListsLoading || isUserLoading || isSelectionLoading);

  const onDeleteSavedSelection = (id) => {
    mutateDeleteSavedSelections(id, {
      onSuccess: () => {
        refetchSavedSelections();
      },
    });
  };

  const onDeleteUserLists = (id) => {
    mutateDeleteUserLists(id, {
      onSuccess: () => {
        refetchSavedUsers();
      },
    });
  };

  useEffect(() => {
    resetGraphType();
    resetFilters();
    resetPeriod();
    resetUsersState();
    resetCurrentSelection();
    document.title = 'Kuben Analytics - Följ upp effekter';
  }, []);

  if (isSelectionError || isUserError) {
    return <div>Ett fel inträffade</div>;
  }

  if (isDeleteSavedSelectionError || isDeleteUserListsError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIContainerCard>
      <div className="wizard-box h-full w-full py-10 px-14 flex justify-center items-center lg:px-12 md:px-6">
        <div
          className='absolute top-14 left-16'
        >

        <UIBackButton/>
        </div>
        <div className="w-3/4 max-h-91p h-full flex flex-col items-center justify-between mx-auto">
          <div className="text-4xl title font-bold pb-14">Vad vill du göra uppföljning på?</div>
          {isLoading && 
          <div
            className='h-16 w-16 absolute top-48'
          >
            <UISpinner
              theme='normal'
            />
          </div>}
          <div className="flex justify-between w-full flex-grow">
            <UISavedSelectionList
              title="Tidigare urval"
              data={savedSelectionsList}
              deleteElement={(id) => onDeleteSavedSelection(id)}
            />
            <UISavedSelectionList
              title="Individer från urval"
              data={savedUsersList}
              isUserList={true}
              deleteElement={(id) => onDeleteUserLists(id)}
            />
          </div>
        </div>
      </div>
    </UIContainerCard>
  );
};

export default PageSavedSelection;
