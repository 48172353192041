import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import usePersonSearch from 'hooks/api/person/usePersonSearch';
import tableElements from 'utils/KubenAdmin/PersonPage/tableElements';
import { personSearch as personSearchRecoil } from 'state/KubenAdmin/persons';

import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UITablePagination from 'components/global/UICommon/UITablePagination';
import UIInput from 'components/global/UICommon/UIInput';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';

import UICardWrapper from 'components/KubenAdmin/UIAdmin/UICardWrapper';
import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UITable from 'components/KubenAdmin/UITable/UITable';
import UITableElementWrapper from 'components/KubenAdmin/UITable/UITableElementWrapper';
import UITableElement from 'components/KubenAdmin/UITable/UITableElement';

const PAGE_SIZE = 49;
const DATA_SIZE = 50;

const PersonPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const data = {
    pageSize: DATA_SIZE,
    page: page,
    text: search,
  };
  const {
    isLoading: isPersonSearchLoading,
    isFetching,
    isError: isPersonSearchError,
    refetch,
  } = usePersonSearch(page, data);
  const personData = useRecoilValue(personSearchRecoil);
  const resetPersonData = useResetRecoilState(personSearchRecoil);

  useEffect(() => {
    resetPersonData();
  }, []);

  const onSearch = () => {
    refetch();
    setPage(1);
  };

  useEffect(() => {
    refetch();
  }, [page, location]);

  if (isPersonSearchLoading || isFetching) {
    return (
      <UILoadingModal
      />
    );
  }

  if (isPersonSearchError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UITitle title="ADMINISTRATION AV PERSONER" />
      <UICardWrapper title="SÖK EFTER PERSON">
        <UIInput
          value={search}
          onChange={setSearch}
          type="search"
          placeholder="Personer"
        />
      </UICardWrapper>
      <div className="w-full flex justify-center">
        <UIButtonSubmit
          name="Sök"
          assessments={true}
          isIcon={false}
          onSubmit={onSearch}
        />
      </div>
      {personData != null && (
        <div className="mt-28 mb-10 w-full">
          <UITitle title="PERSONER" />
          <UITable tableElements={tableElements}>
            {personData.length > 0 ? (
              personData.map((el, idx) => (
                <UITableElementWrapper
                  key={idx}
                  isLink={true}
                  link={`persons/${el.id}`}
                >
                  <UITableElement name={el.name} />
                  <UITableElement name={el.socialSecurityNumber} />
                  <UITableElement name={el.personId} />
                </UITableElementWrapper>
              ))
            ) : (
              <div className="py-8">Inga personer att visa</div>
            )}
          </UITable>
          {(personData.length > PAGE_SIZE || page !== 1) && (
            <UITablePagination
              page={page}
              setPage={setPage}
              pageSize={PAGE_SIZE}
              data={personData}
            />
          )}
        </div>
      )}
    </UIStaticComponentsWrapper>
  );
};

export default PersonPage;
