import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { currentYear } from 'config/constants';
import { currentSelection } from 'state/KubenAnalytics/current/currentSelection';
import { usersHoursCurrentYearTotal, usersPredictedHours } from 'state/KubenAnalytics/users/usersValues';
import { usersPeriodsType } from 'state/KubenAnalytics/users/usersPeriods';
import { currentAnalyticsModel } from 'state/KubenAnalytics/analytics/models';
import { fetchUsers } from 'hooks/api/analytics/useFetchUsers';
import {
  predictionSliderAssessment,
  predictionSliderDevelopment,
  predictionSliderUser,
} from 'state/KubenAnalytics/predictions/sliders';
import { currentColorTheme } from 'state/global/currentUser';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';

import UIErrorModal from 'components/KubenAnalytics/UIAnalytics/UIErrorModal';
import UIBreadcrumbs from 'components/KubenAnalytics/UIAnalytics/UIBreadcrumbs';
import UIButtonSlider from 'components/KubenAnalytics/UIAnalytics/UIButtonSlider';
import AppBarChartStats from 'components/KubenAnalytics/AppBarChart/AppBarChartStats';
import AppGraphsChartContainer from 'components/KubenAnalytics/AppGraphs/AppGraphsChartContainer';
import AppGraphsLineChart from 'components/KubenAnalytics/AppGraphs/AppGraphsLineChart';

const PageLineChart = () => {
  const analyticModel = useRecoilValue(currentAnalyticsModel);
  const selection = useRecoilValue(currentSelection);
  const { isLoading, isError } = fetchUsers(selection, analyticModel);
  const setPeriodsType = useSetRecoilState(usersPeriodsType);
  const usersHours = useRecoilValue(usersHoursCurrentYearTotal);
  const usersHoursPrediction = useRecoilValue(usersPredictedHours);
  const [sliderUser, setSliderUser] = useRecoilState(predictionSliderUser);
  const [sliderAssessment, setSliderAssessment] = useRecoilState(predictionSliderAssessment);
  const [sliderDevelopment, setSliderDevelopment] = useRecoilState(predictionSliderDevelopment);
  const resetSliderUser = useResetRecoilState(predictionSliderUser);
  const resetSliderAssessment = useResetRecoilState(predictionSliderAssessment);
  const resetSliderDevelopment = useResetRecoilState(predictionSliderDevelopment);
  const colorTheme = useRecoilValue(currentColorTheme);

  useEffect(() => {
    setPeriodsType('months');
    resetSliderUser();
    resetSliderAssessment();
    resetSliderDevelopment();
    document.title = 'Kuben Analytics - Prediction';
  }, []);

  if (isError) {
    return <UIErrorModal message="Hoppsan, något gick fel med urvalet." />;
  }

  return (
    <>
    {isLoading && 
      <UILoadingModal customMessage="Samlar data från ditt urval.." />
    }
      <AppBarChartStats />
      <AppGraphsChartContainer>
        <div className="h-full flex flex-col justify-between">
          <div className="w-full flex justify-between">
            <UIBreadcrumbs type="prediction" />
          </div>
          <div className="w-full h-full">
            <div className="flex w-full h-4/5">
              <AppGraphsLineChart
                historicData={usersHours}
                predictionData={usersHoursPrediction}
                colorTheme={colorTheme}
              />
            </div>
            <div className="flex w-full border-r-2 pl-12 pr-4 mt-1">
              <div className="w-12/35 h-0.5 background-body border-r-2 text-center">
                <p className="text-sm font-semibold mt-2">{currentYear}</p>
              </div>
              <div className="w-12/35 h-0.5 background-body border-r-2 text-center">
                <p className="text-sm font-semibold mt-2">{currentYear + 1}</p>
              </div>
              <div className="w-11/35 h-0.5 background-body border-r-2 text-center">
                <p className="text-sm font-semibold mt-2">{currentYear + 2}</p>
              </div>
            </div>
            <div className="w-full flex justify-between items-end px-2 h-1/5 space-x-8">
              <UIButtonSlider
                title="Ändrat arbetssätt"
                value={sliderUser}
                setValue={setSliderUser}
              />
              <UIButtonSlider
                title="Förändrad brukarmix"
                value={sliderAssessment}
                setValue={setSliderAssessment}
              />
              <UIButtonSlider
                title="Befolkningsutveckling"
                value={sliderDevelopment}
                setValue={setSliderDevelopment}
              />
            </div>
          </div>
        </div>
      </AppGraphsChartContainer>
    </>
  );
};

export default PageLineChart;
