import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import UISpinner from '../UICommon/UISpinner';
import { useTranslation } from 'react-i18next';

const UILoadingModal = ({
  customMessage,
  isMessage,
  messageStyle,
  isOpen = true,
  status,
  infoMessage,
  onClose,
  setStopRefetchingJob,
}) => {
  const {t} = useTranslation();
  const [showInfo, setShowInfo] = useState(false);
  const MAX_WAIT = 30_000;

  if (!customMessage) {
    customMessage=t('UIModals.loadingModalMessage');
  } else {
    isMessage = true;
  }

  // show popup if status is not done in 30sec
  useEffect(() => {
    if (status != null) {
      const timer = setInterval(() => {
        if (status !== 'Finished' && Date.now() - startTime >= MAX_WAIT) {
          setShowInfo(true);
          setStopRefetchingJob(true);
        }
      }, MAX_WAIT);

      const startTime = Date.now();

      return () => {
        clearInterval(timer);
      };
    }
  }, [status]);

  return (
      <div className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-64 w-64
       ${isOpen ? 'visible' : 'hidden'}`}>
        <div className="text-center">
          {showInfo ? (
            <div>
              <div className="pb-10 font-semibold">{infoMessage}</div>
              <div
                onClick={() => onClose()}
                className="cursor-pointer rounded button-primary px-3 py-2"
              >
                OK
              </div>
            </div>
          ) : (
            <div
              className='flex flex-col space-y-4 h-64 items-center'
            >
              <UISpinner theme="arrows" />
              {isMessage && 
              <p
                className={`${messageStyle || 'text-primary'} text-sm absolute -bottom-12 font-semibold`}
              >{customMessage}</p>}
            </div>
          )}
        </div>
      </div>
  );
};

UILoadingModal.propTypes = {
  customMessage: PropTypes.string,
  isMessage: PropTypes.bool,
  messageStyle: PropTypes.string,
  isOpen: PropTypes.bool,
  status: PropTypes.string,
  infoMessage: PropTypes.string,
  onClose: PropTypes.func,
  setStopRefetchingJob: PropTypes.func,
  isOverlay: PropTypes.bool,
};

export default UILoadingModal;
