import React from 'react';
import propTypes from 'prop-types';

const ArrowLoader = ({className}) => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 283.46 283.46"
            width="100%" 
            height="100%"
            className={className}
        >
            <polygon 
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="3 0"
                points="
                    0 0, 
                    0 48.19, 
                    201.2 48.19, 
                    0 249.38, 
                    34.07 283.46, 
                    235.28 82.26, 
                    235.28 283.28, 
                    283.46 283.28, 
                    283.46 0, 
                    0 0
                "
            />
        </svg>
    );
};

ArrowLoader.propTypes = {
    className: propTypes.string,
};

export default ArrowLoader;
