import React, { useEffect, useState } from 'react';
import ArrowLoader from 'components/icons/loader/ArrowLoader';

const UIArrowLoader = () => {
  const animationDuration = 250;
  const highlightedScale = 1.1;
  const gridSize = 3;
  const [grid, setGrid] = useState(
    [
      [highlightedScale, 1,   1,    1,   1],
      [1,   highlightedScale, 1,    1,   1],
      [1,   1,   highlightedScale,  1,   1],
      [1,   1,   1,    highlightedScale, 1],
      [1,   1,   1,    1,   highlightedScale]
    ]
  ); 
  const colors = ['var(--primary)', 'var(--counter)', 'var(--accent)'];

  const [arrows, setArrows] = useState([]);

  const rotateGrid = () =>  {
    const tmp = [...grid];

    for (let row = 0; row < tmp[0].length; row++) {
      const arr = tmp[row];
      arr.unshift(arr.pop());
    }

    return tmp;
  };

  useEffect(() => {
    const createArrows = () => {

      setGrid(rotateGrid());

      const newArrows = [];
      for (let row = 0; row < gridSize; row++) {
        for (let col = 0; col < gridSize; col++) {
          const scale = grid[row][col];
          newArrows.push({
            id: `${row}-${col}`,
            scale: scale,
            color: row == 0 && col == gridSize - 1 ? colors[2] :
             row == gridSize - 1 && col == 0 ? colors[0] : colors[1],
            opacity: scale == highlightedScale ? .7 : 1,
          });
        }
      }
      setArrows(newArrows);
    };

    const intervalId = setInterval(createArrows, animationDuration);

    return () => clearInterval(intervalId); 
  }, []);

  return (
    <div className={`w-full h-full grid grid-cols-${gridSize} gap-4`}>
      {arrows.map((arrow) => (
        <div
          key={arrow.id}
          className={`relative flex items-center justify-center
             transition-all duration-[${animationDuration*2}ms] ease`}
          style={{
            transform: `scale(${arrow.scale})`,
            color: `${arrow.color}`,
            opacity: `${arrow.opacity}`,
          }}
        >
          <ArrowLoader />
        </div>
      ))}
    </div>
  );
};

export default UIArrowLoader;
