import React from 'react';
import PropTypes from 'prop-types';
import { FaCircle, FaRegCircle } from 'react-icons/fa';

const AppWizardProgressBar = ({ steps, activeStepIndex }) => {
  return (
    <div className="shadow-md p-2 px-8 flex w-full justify-around rounded-xl progress-bar">
      {steps.map((val, idx, arr) => {
        const color = idx <= activeStepIndex ? 'current' : 'other';
        const divisor = idx < arr.length - 1 ? arr.length - 1 : 0;
        const circleIcon =
          idx <= activeStepIndex ? <FaCircle className="current" /> : <FaRegCircle className="other" />;

        if (val != null)
        return (
          <div
            key={idx}
            className={`flex font-bold items-end justify-around w-1/${divisor}`}
          >
            <div className="flex flex-col items-center">
              <p className={`${color}`}>{val}</p>
              {circleIcon}
            </div>
            <div className={`h-0.5 mb-2 rounded w-full ${color}`}></div>
          </div>
        );
      })}
    </div>
  );
};

AppWizardProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeStepIndex: PropTypes.number.isRequired,
};

export default AppWizardProgressBar;
