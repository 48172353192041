import propTypes from 'prop-types';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, BarChart, Bar, Tooltip } from 'recharts';
import { graphDrillDownData, graphXTicks } from 'state/KubenDashboard/graphs';
import formatNumber from 'utils/KubenDashboard/formatNumber';
import GraphXAxisLabelOption from 'config/graphXAxisLabelOption';
import DashboardChartToolTip from './DashboardChartToolTip';

const DashboardBarChart = ({ chartData, activeTitles, legendHoverItem, isEdit }) => {
  const [drillDownData, setDrillDownData] = useRecoilState(graphDrillDownData);
  const [focusBar, setFocusBar] = useState({});
  const [maxYTickLength, setMaxYTickLength] = useState(-1);
  const xTickState = useRecoilValue(graphXTicks);

  const formattedYLabels = useMemo(() => 
    chartData.data.map(dataPoint => `${formatNumber(dataPoint.value)} ${chartData.units}`)
  , [chartData.data, chartData.units]);

  useEffect(() => {
    const maxLabelLength = Math.max(...formattedYLabels.map(label => label.length));
    setMaxYTickLength(maxLabelLength);
  }, [formattedYLabels]);

  const YTickFormatter = useCallback(
    (value) => `${formatNumber(value)} ${chartData.units}`, 
    [chartData.units]
  );

  const XTickFormatter = useCallback(
    (value, index) => {
      if (xTickState === GraphXAxisLabelOption.FULL) return value;
      if (xTickState === GraphXAxisLabelOption.INDEX) return index;
      return value.substring(0, 3);
    },
    [xTickState]
  );

  const onClick = useCallback(
    (data, title) => {
      if (
        isEdit ||
        (data.payload.drillDownDataKey === drillDownData.data && title.drillDownTitleKey === drillDownData.title)
      ) {
        return;
      }
      setDrillDownData({
        graphId: chartData.id,
        title: title.drillDownTitleKey,
        titleLabel: data.name,
        data: data.payload.drillDownDataKey,
        dataLabel: title.name,
      });
    },
    [isEdit, drillDownData, chartData.id, setDrillDownData]
  );

  const onHover = useCallback(
    (data, title) => {
      if (
        isEdit ||
        (focusBar?.data === data.drillDownDataKey && focusBar?.title === title.drillDownTitleKey)
      ) {
        return;
      }
      setFocusBar({
        data: data.drillDownDataKey,
        title: title.drillDownTitleKey,
      });
    },
    [isEdit, focusBar]
  );

  const getCustomBar = useCallback(
    (props, title) => {
      // eslint-disable-next-line react/prop-types
      const { x, y, width, height, fill, name, drillDownDataKey } = props;
      const opacity = 
        legendHoverItem === name ||
        (focusBar?.data === drillDownDataKey && focusBar.title === title.drillDownTitleKey) ||
        (drillDownData.data === drillDownDataKey && drillDownData.title === title.drillDownTitleKey) 
        ? 0.7 
        : 1;
      return <rect x={x} y={y} width={width} height={height} opacity={opacity} fill={fill} />;
    },
    [legendHoverItem, focusBar, drillDownData]
  );

  return (
    <ResponsiveContainer width="100%" height="100%" animationDuration={0}>
      <BarChart
        data={chartData.data}
        barCategoryGap={3}
        barSize={20}
        margin={{
          top: 10,
          right: 25,
          left: 5,
          bottom: xTickState !== GraphXAxisLabelOption.NONE ? 5 : 0,
        }}
      >
        <CartesianGrid vertical={false} />
        {xTickState !== GraphXAxisLabelOption.NONE && (
          <XAxis
            dataKey="name"
            tickMargin={10}
            tick={{ fill: 'hsl(0, 0%, 60%)' }}
            axisLine={false}
            tickLine={false}
            tickFormatter={XTickFormatter}
          />
        )}
        <YAxis
          tickFormatter={YTickFormatter}
          animationDuration={0}
          axisLine={false}
          tickLine={false}
          tickSize={10}
          width={maxYTickLength * 8.8}
          tick={{ fill: 'hsl(0, 0%, 60%)' }}
          style={{ fontSize: '14px' }}
        />
        {!isEdit && (
          <Tooltip
            animationDuration={500}
            cursor={false}
            content={(data) => (
              <DashboardChartToolTip
                toolTipData={data}
                chartData={chartData}
                hasXTicks={xTickState !== GraphXAxisLabelOption.NONE}
              />
            )}
          />
        )}
        {chartData.titles.map((title, idx) => (
          <Bar
            shape={(props) => getCustomBar(props, title)}
            onClick={(data) => onClick(data, title)}
            onMouseMove={(data) => onHover(data, title)}
            onMouseLeave={() => setFocusBar(null)}
            key={idx}
            dataKey={title.id}
            stackId={chartData.options.chartType === 'stacked' ? 'a' : title.id}
            fill={title.color}
            hide={!activeTitles.includes(title.id)}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

DashboardBarChart.propTypes = {
  chartData: propTypes.any,
  activeTitles: propTypes.array,
  legendHoverItem: propTypes.string,
  isEdit: propTypes.bool.isRequired,
};

export default DashboardBarChart;
