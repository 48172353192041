import React from 'react';
import PropTypes from 'prop-types';
import { FiChevronRight } from 'react-icons/fi';

const AppNewsBox = ({ title, paragraph, date, link }) => {
  return (
    <div className='news-box rounded-[5px]'>
      <div className='text-left mt-4 lg:mt-2 py-3 px-4'>
        <p className="font-semibold xl:text-sm lg:text-sm uppercase mb-0 title secondary">
          {title.length > 20 ? title.substring(0, 20) + '...' : title}
        </p>
        <p className="font-semibold xl:text-xs lg:text-xs uppercase date">{date}</p>
        <p className="mt-2 text-xs text opacity-70">
          {paragraph.length > 150 ? paragraph.substring(0, 150) + '...' : paragraph}
        </p>
        {link && (
        <div
          className='w-full flex justify-end hover:underline'
        >
            <a
              className="font-bold text-xs flex"
              target="_blank"
              rel="noreferrer"
              href={link}
            >
              <label>Läs mer</label>
              <FiChevronRight className="h-4 w-4 ml-1 title" />
            </a>
       </div>)}
      </div>
    </div>
  );
};

AppNewsBox.propTypes = {
  title: PropTypes.string,
  paragraph: PropTypes.string,
  date: PropTypes.string,
  link: PropTypes.string,
};

export default AppNewsBox;
