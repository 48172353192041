import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import modifyDataForSortableTree from 'utils/KubenAdmin/UsersPageDetails/modifyDataForSortableTree';
import useUserDetails from 'hooks/api/user/useUserDetails';
import useFetchTemplates from 'hooks/api/assessment/useFetchTemplates';
import useFetchUserRegions from 'hooks/api/user/useFetchUserRegions';
import useUserActivity from 'hooks/api/user/useUserActivity';
import useUserEdit from 'hooks/api/user/useUserEdit';
import useFetchUserRoles from 'hooks/api/user/useFetchUserRoles';
import { allTemplatesFiltered as templatesRecoil } from 'state/global/templates';
import {
  userDetails as userDetailsRecoil,
  userActivity as userActivityRecoil,
  userRegions as userRegionsRecoil,
  userRoles as userRolesRecoil,
  modifyedUserRegions as modifyedUserRegionsRecoil,
  originalUserDetails as originalUserDetailsRecoil,
} from 'state/KubenAdmin/users';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIErrorMessage from 'components/global/UICommon/UIErrorMessage';

import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UIFoldableCard from 'components/KubenAdmin/UIAdmin/UIFoldableCard';
import UIModalWithoutSaveData from 'components/KubenAdmin/UIAdmin/UIModalWithoutSaveData';

import UserDetailsActivity from 'components/KubenAdmin/UsersPage/UserDetailsActivity';
import UserDetailsTemplatesList from 'components/KubenAdmin/UsersPage/UserDetailsTemplatesList';
import UserDetailsForm from 'components/KubenAdmin/UsersPage/UserDetailsForm';
import UserDetailsPermissions from 'components/KubenAdmin/UsersPage/UserDetailsPermissions';

const UsersPageDetails = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [activityPage, setActivityPage] = useState(1);
  const pageSize = 50;

  // get saved data
  const [userDetails, setUserDetails] = useRecoilState(userDetailsRecoil);
  const [originalUserDetails, setOriginalUserDetails] = useRecoilState(originalUserDetailsRecoil);
  const [modifyedUserRegions, setModifyedUserRegions] = useRecoilState(modifyedUserRegionsRecoil);
  const usersActivity = useRecoilValue(userActivityRecoil);
  const allTemplates = useRecoilValue(templatesRecoil);
  const userRegions = useRecoilValue(userRegionsRecoil);
  const userRoles = useRecoilValue(userRolesRecoil);

  // getting data from api
  const {
    isLoading: isUserDetailsLoading,
    isError: isUserDetailsError,
    refetch: refetchUserDetails,
  } = useUserDetails(id);
  const {
    isLoading: isFetchingTemplatesLoading,
    isError: isFetchingTemplatesError,
    refetch: refetchTemplates,
  } = useFetchTemplates(false);
  const {
    isLoading: isUserRegionsLoading,
    isError: isUserRegionsError,
    refetch: refetchUserRegions,
  } = useFetchUserRegions();
  const {
    isLoading: isUserActivityLoading,
    isFetching: isUserActivityFetching,
    isError: isUserActivityError,
    refetch: refetchUserActivity,
  } = useUserActivity(id, activityPage, pageSize);
  const { isLoading: isUserEditLoading, isError: isUserEditError, mutate: mutateUserEdit } = useUserEdit(userDetails);
  const { isLoading: isUserRolesLoading, isError: isUserRolesError } = useFetchUserRoles();

  const handleChooseTemplates = (choosenElements) => {
    setUserDetails({ ...userDetails, assessmentTemplates: choosenElements });
  };

  const handleAddPermission = (listOfRoles, nodeId) => {
    const newPermissions = userDetails.userSecurityRoles.filter((el) => el.organizationUnitId !== nodeId);
    const permissionsToAdd = listOfRoles.map((el) => ({
      organizationUnitId: nodeId,
      securityRoleId: el.id,
    }));
    const newUserPermissions = [...newPermissions, ...permissionsToAdd];
    setUserDetails({ ...userDetails, userSecurityRoles: newUserPermissions });
  };

  const handleUserPermissionClicked = (permission, newValue) => {
    if (permission === 'superAdmin') {
      setUserDetails({ ...userDetails, superAdministrator: newValue });
    } else if (permission === 'systemUser') {
      setUserDetails({ ...userDetails, systemUser: newValue });
    }
  };

  useEffect(() => {
    refetchTemplates();
    refetchUserDetails();
    refetchUserRegions();
  }, [id]);

  useEffect(() => {
    refetchUserActivity();
  }, [activityPage]);

  useEffect(() => {
    if (userRegions.length !== 0 && modifyedUserRegions.length === 0) {
      modifyDataForSortableTree(userRegions, setModifyedUserRegions);
    }
  }, [userRegions]);

  const onSave = (backToHomePage) => {
    mutateUserEdit();
    setOriginalUserDetails(userDetails);
    if (!isUserEditError && !isUserEditLoading && backToHomePage) {
      history.goBack();
    }
  };

  if (
    isUserDetailsLoading ||
    isUserEditLoading ||
    isUserActivityLoading ||
    isUserRegionsLoading ||
    isFetchingTemplatesLoading ||
    isUserRolesLoading ||
    modifyedUserRegions.length === 0
  ) {
    return (
      <UILoadingModal
      />
    );
  }

  if (isUserDetailsError || isUserEditError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UIModalWithoutSaveData
        elementToCompare={userDetails}
        orginalElement={originalUserDetails}
      />
      <UITitle title={'ANVÄNDARE: ' + userDetails.name} />
      <div className="space-y-8 w-full mt-4">
        <UIFoldableCard
          title="Egenskaper"
          defaultOpen={true}
        >
          <UserDetailsForm
            data={userDetails}
            setData={setUserDetails}
          />
        </UIFoldableCard>
        <UIFoldableCard title="Mallar">
          <UserDetailsTemplatesList
            array={userDetails?.assessmentTemplates}
            dataforModal={allTemplates}
            isError={isFetchingTemplatesError}
            choosenElements={userDetails?.assessmentTemplates}
            handleClick={handleChooseTemplates}
          />
        </UIFoldableCard>
        <UIFoldableCard title="Rättigheter">
          <UserDetailsPermissions
            array={modifyedUserRegions}
            setArray={setModifyedUserRegions}
            roles={userRoles}
            isUserRegionsError={isUserRegionsError}
            isUserRolesError={isUserRolesError}
            isSuperAdministrator={userDetails?.superAdministrator}
            isSystemUser={userDetails?.systemUser}
            handleClick={handleAddPermission}
            onUserPermissionClick={handleUserPermissionClicked}
            choosenRegions={userDetails?.userSecurityRoles}
          />
        </UIFoldableCard>
        <UIFoldableCard title="Aktivitet">
          {isUserActivityError ? (
            <UIErrorMessage />
          ) : (
            <UserDetailsActivity
              data={usersActivity}
              page={activityPage}
              setPage={setActivityPage}
              pageSize={pageSize}
              isFetching={isUserActivityFetching}
            />
          )}
        </UIFoldableCard>
        <div className="flex w-full space-x-5 justify-center">
          <UIButtonSubmit
            name="Spara"
            variant="button-primary"
            assessments={true}
            isIcon={false}
            onSubmit={() => onSave(false)}
          />
          <UIButtonSubmit
            name="Spara och stäng"
            variant="button-primary"
            assessments={true}
            isIcon={false}
            onSubmit={() => onSave(true)}
          />
        </div>
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default UsersPageDetails;
