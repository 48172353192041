import React from 'react';

const AssessmentCreateImage = () => {
  return (
    <svg
      className="pageImage"
       width="100%"
      height="100%"
      viewBox="0 0 130 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.266113"
        y="0.5"
        width="129.468"
        height="92"
        rx="4"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="0.266113"
        y="0.5"
        width="25.2258"
        height="92"
        rx="4"
        fill="currentColor"
        className="primary"
      />
      <ellipse
        cx="4.90325"
        cy="4.76604"
        rx="0.927419"
        ry="0.927419"
        fill="currentColor"
        className="primary"
      />
      <ellipse
        cx="7.87102"
        cy="4.76604"
        rx="0.927419"
        ry="0.927419"
        fill="currentColor"
        className="bg"
      />
      <ellipse
        cx="11.0242"
        cy="4.76604"
        rx="0.741936"
        ry="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="58.1371"
        y="23.129"
        width="35.2419"
        height="2.22581"
        rx="1.1129"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="49.6049"
        y="29.4354"
        width="52.3064"
        height="27.4516"
        rx="4"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="3.97583"
        y="9.4032"
        width="18.1774"
        height="4.08065"
        rx="2.04032"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M19.9274 11.2581V11.2581C20.1322 11.4629 20.4644 11.4629 20.6693 11.2581V11.2581"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="37.5968"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="85.4517"
        width="2.22581"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="secondary"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="33.5161"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="28.6936"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="24.2419"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.83057"
        y="37.9678"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="5.83057"
        y="85.4517"
        width="10.0161"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="secondary"
      />
      <rect
        opacity="0.6"
        x="5.83057"
        y="33.8871"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.83057"
        y="29.0646"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.83057"
        y="24.2419"
        width="14.8387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="55.1693"
        y="37.2258"
        width="41.1774"
        height="3.33871"
        rx="1.66935"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="88.9275"
        y="60.9678"
        width="8.90323"
        height="2.96774"
        rx="1.48387"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="49.6049"
        y="61.7097"
        width="11.129"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="55.1693"
        y="34.2581"
        width="14.4677"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="55.1693"
        y="52.0646"
        width="10.0161"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="65.9274"
        y="52.0646"
        width="5.56452"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="72.2338"
        y="52.0646"
        width="14.0968"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
    </svg>
  );
};

export default AssessmentCreateImage;
