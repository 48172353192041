import React from 'react';
import PropTypes from 'prop-types';

import { BsX } from 'react-icons/bs';

const UIButtonSavedSelection = ({ name, navigateToGraph, onClick }) => {
  return (
    <div
      className={
        'w-full rounded-md h-16 shadow-md button-primary mb-3 ' +
        'flex justify-center items-center'
      }
    >
      <button
        onClick={navigateToGraph}
        className="flex-grow h-full"
      >
        <div className="ml-11 font-semibold text-xl capitalize">{name}</div>
      </button>
      <div onClick={onClick}>
        <BsX className="text-[30px] opacity-50 hover:opacity-100 mr-5 mt-0.5 cursor-pointer" />
      </div>
    </div>
  );
};

UIButtonSavedSelection.propTypes = {
  name: PropTypes.string.isRequired,
  navigateToGraph: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default UIButtonSavedSelection;
