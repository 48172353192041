import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';
import { IoIosArrowDown, IoIosArrowUp, IoMdClose } from 'react-icons/io';
import { IsOverlayActive } from 'state/global/overlay';
import closeElementFunction from 'utils/global/closeElementFunction';
import GraphFilterDropdownElementItem from './GraphFilterDropdownElementItem';

const GraphFilterDropdownElement = ({ title, removeFilter, chosenFilters, tableKey }) => {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const setIsDashboardOverlayVisible = useSetRecoilState(IsOverlayActive);
  const wrapperRef = useRef(null);
  closeElementFunction(wrapperRef, setToggleDropdown);

  useEffect(() => {
    setIsDashboardOverlayVisible(toggleDropdown);
  }, [toggleDropdown]);

  useEffect(() => {
    if (chosenFilters.length == 0 && toggleDropdown) {
      setToggleDropdown(false);
    }
  }, [chosenFilters]);

  const handleToggleDropdown = () => {
    if (chosenFilters) {
      setToggleDropdown(!toggleDropdown);
    }
  };

  return (
    <div>
      <button
        onClick={handleToggleDropdown}
        className={`background border border-tertiary rounded-md flex items-center justify-center p-2 h-full ${
          toggleDropdown ? 'z-40 relative' : ''
        }`}
      >
        <p>{`${title} (${chosenFilters.length})`}</p>
        {chosenFilters && (
          toggleDropdown ? <IoIosArrowUp className="title-summary ml-2" /> :
           <IoIosArrowDown className="title-summary ml-2" />
        )}
      </button>
      {chosenFilters && toggleDropdown && (
        <div ref={wrapperRef} className="absolute background shadow-md rounded-lg top-12 z-[100]">
          <div className="flex justify-between items-center border-b px-5 py-3">
            <h2 className="text-left font-semibold">Dina val</h2>
            <IoMdClose
              onClick={() => setToggleDropdown(false)}
              className="cursor-pointer text-xl"
            />
          </div>
          <div className="flex flex-wrap w-[max-content] max-w-[550px] px-3 py-5 max-h-[500px] overflow-y-scroll">
            {chosenFilters.map((filter, idx) => 
              <GraphFilterDropdownElementItem
                key={idx}
                id={filter.id}
                name={filter.name}
                onClick={() => removeFilter(filter.id, tableKey)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

GraphFilterDropdownElement.propTypes = {
  title: PropTypes.string.isRequired,
  removeFilter: PropTypes.func.isRequired,
  chosenFilters: PropTypes.array.isRequired,
  tableKey: PropTypes.string.isRequired,
};

export default GraphFilterDropdownElement;
