import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import { FaMinusSquare } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import extractFiltersIdsToExpand from 'utils/KubenDashboard/extractFiltersIdsToExpand';

import AddIcon from 'components/icons/global/AddIcon';
import MinusIcon from 'components/icons/global/MinusIcon';

import checkIcon from '../../../../assets/KubenDashboard/checked.svg';
import uncheckIcon from '../../../../assets/KubenDashboard/unchecked.svg';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const GraphFiltersCheckboxes = ({ chosenFilters, setChosenFilters, activeTab, search, filters }) => {
  const [expanded, setExpanded] = useState([]);
  const [nodesFiltered, setNodesFiltered] = useState();

  useEffect(() => {
    filterTree(filters[activeTab]);
  }, [search, activeTab]);

  const filterTree = (nodes) => {
    if (!search || search === '' || search.length === 0) {
      setNodesFiltered(nodes);
      setExpanded([]);
      return;
    }

    const reducedFilters = nodes.reduce(filterNodes, []);
    const expandedFilters = extractFiltersIdsToExpand(reducedFilters, search);
    setExpanded(expandedFilters);
    setNodesFiltered(reducedFilters);
  };

  const filterNodes = (filtered, node) => {
    const children = (node.children || []).reduce(filterNodes, []);
    if (node.label.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1 || children.length) {
      filtered.push({ ...node, ...(children.length && { children }) });
    }
    return filtered;
  };

  return (
    <div
      className='pb-12 pt-4'
    >
      <h2 className="text-left font-semibold text-lg text">Områden</h2>
      <div className="max-h-[250px] xl:max-h-[350px] 3xl:max-h-[400px] py-1 scroll-on-hover">
        {nodesFiltered && (
          <CheckboxTree
            nodes={nodesFiltered}
            checked={chosenFilters[activeTab]}
            expanded={expanded}
            onCheck={(checked) => setChosenFilters({ ...chosenFilters, [activeTab]: checked })}
            onExpand={(expanded) => setExpanded(expanded)}
            showNodeIcon={false}
            showExpandAll={true}
            checkModel="leaf"
            icons={{
              check: <img src={checkIcon} />,
              uncheck: <img src={uncheckIcon} />,
              halfCheck: <FaMinusSquare />,
              expandClose: <IoIosArrowDown className="title" />,
              expandOpen: <IoIosArrowUp className="title" />,
              expandAll: <AddIcon />,
              collapseAll: <MinusIcon />,
            }}
          />
        )}
      </div>
    </div>
  );
};

GraphFiltersCheckboxes.propTypes = {
  activeTab: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  chosenFilters: PropTypes.object.isRequired,
  setChosenFilters: PropTypes.func.isRequired,
};

export default GraphFiltersCheckboxes;
