import React from 'react';
import { Route, Switch } from 'react-router-dom';


import PageOverview from '../../../pages/KubenAnalytics/PageOverview';
import PageAnalyticsWrapper from '../../../pages/KubenAnalytics/PageAnalyticsWrapper';
import UISidebarWrapper from '../../KubenAnalytics/UIAnalytics/UISidebarWrapper';

const AppAnalyticsLayout = () => {
  return (
    <div className={`h-full w-full`}>
      {/* TopBarMenu */}
      <div className="flex justify-between items-stretch xl:items-center h-full">
        {/* MainContainer */}
        <div className="pt-8 xl:pt-0 xl:h-full flex flex-col justify-between flex-grow px-2 xl:pr-12 relative">
          <Switch>
            {/* wrapper is for fetching analytic models because a lot of states are using them and if
                we jump by shortcut from dashboard we need to fetch them first*/}
            <Route
              exact
              path="/analytics/"
              component={PageOverview}
            />
            <Route
              exact
              path="/analytics/filter"
              component={PageAnalyticsWrapper}
            />
            <Route
              exact
              path="/analytics/saved-selection"
              component={PageAnalyticsWrapper}
            />
            <Route
              exact
              path="/analytics/effects"
              component={PageAnalyticsWrapper}
            />
            <Route
              exact
              path="/analytics/results"
              component={PageAnalyticsWrapper}
            />
            <Route
              exact
              path="/analytics/prediction"
              component={PageAnalyticsWrapper}
            />
            <Route
              exact
              path="/analytics/list"
              component={PageAnalyticsWrapper}
            />
          </Switch>
        </div>
        {/* SidePanel */}
        <div className="pt-8 xl:pt-0 h-9/10 pr-6">
          <Switch>
            {/* wrapper is for fetching analytic models because a lot of states are using them and if
                we jump by shortcut from dashboard we need to fetch them first*/}
            <Route
              exact
              path="/analytics/results"
              component={UISidebarWrapper}
            />
            <Route
              exact
              path="/analytics/prediction"
              component={UISidebarWrapper}
            />
            <Route
              exact
              path="/analytics/list"
              component={UISidebarWrapper}
            />
            <Route
              exact
              path="/analytics/effects"
              component={UISidebarWrapper}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AppAnalyticsLayout;
