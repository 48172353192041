import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SummaryEstimatedCardHeader = ({ doctorProfession, children }) => {
  const { t } = useTranslation();

  return (
    <div className="rounded-[13px] border border-tertiary content-card">
      <div className="flex justify-between text-2xl pl-8 pr-16 pt-5 pb-6">
        <h2 className="title font-semibold">{t('SummaryPage.summaryHeading')}</h2>
        {doctorProfession && (
          <h3 className="text font-normal">
            {t('SummaryPage.healthcareUnitText')}
            <span className="title font-semibold ml-1">{doctorProfession}</span>
          </h3>
        )}
      </div>
      {children}
    </div>
  );
};

SummaryEstimatedCardHeader.propTypes = {
  doctorProfession: PropTypes.string,
  children: PropTypes.any,
};

export default SummaryEstimatedCardHeader;
