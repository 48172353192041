import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { atom } from 'recoil';

export const graphFilters = atom({
  key: 'graphFilters',
  default: {
    regionNodes: [],
    businessTypes: [],
  },
});

export const graphPeriodFilter = atom({
  key: 'graphPeriodFilter',
  default: {
    startDate: startOfMonth(subMonths(new Date(), 12)),
    endDate: endOfMonth(new Date()),
  },
});

export const graphRegion = atom({
  key: 'graphRegion',
  default: {},
});
