import { BrowserAuthError } from '@azure/msal-browser';
import { msalInstance } from 'utils/global/azureAuth';

const localAuthority = process.env.REACT_APP_LOCAL_AUTHORITY;
const ssoAuthority = process.env.REACT_APP_SSO_AUTHORITY;
const authProviders = process.env.REACT_APP_SSO_AUTHORITY_PROVIDERS.split(',');

const getEmailDomain = (email) => {
  const domainIndex = email.indexOf('@');
  return domainIndex != -1 ? email.slice(domainIndex + 1) : "";
};

const getAuthority = (email) => {
  return authProviders.includes(getEmailDomain(email)) ? 
    ssoAuthority : localAuthority;
};

const prepareRequest = () => ({ 
  authority: getAuthority(msalInstance.getAllAccounts()[0]?.name ?? "") ,
  scopes: [process.env.REACT_APP_CLIENT_ID, 'openid', 'offline_access']
});


const acquireToken = async (email) => {
  const loginRequest = {
    authority: getAuthority(email),
    scopes: [process.env.REACT_APP_CLIENT_ID, 'openid', 'offline_access'],
    extraQueryParameters: { 
      login_hint: email, 
      domain_hint: getEmailDomain(email) 
    }
  };

  return await msalInstance.acquireTokenPopup(loginRequest).catch((error) => {
    if (error instanceof BrowserAuthError) {
      location.reload();
    }
  });
};

const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  msalInstance.logoutRedirect(prepareRequest());
};

const refreshAccessToken = () => {
  const allAccounts = msalInstance.getAllAccounts();
  msalInstance.setActiveAccount(allAccounts[0]);
  return msalInstance.acquireTokenSilent(prepareRequest());
};

const actions = {
  acquireToken,
  refreshAccessToken,
  logout,
};

export default actions;
