import React from 'react';
import PropTypes from 'prop-types';

const AppSwitcherItem = ({ icon, appName, title, hasAccess, onClick, currentApp }) => {
  return (
    <div
      onClick={() => (hasAccess ? onClick(appName, title) : '')}
      className={`flex flex-col rounded-[9px] h-[100px] w-[120px] px-1 py-4 app-switcher-item
        items-center justify-center
        ${hasAccess ? `cursor-pointer` : 'cursor-not-allowed opacity-40'}
        ${currentApp === appName ? 'current-app' : ''}
      `}
    >
        <div className="mx-auto h-2/5">{icon}</div>
        <p className={`mt-1 text-[18px] title`}>{title}</p>
    </div>
  );
};

AppSwitcherItem.propTypes = {
  icon: PropTypes.element.isRequired,
  appName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  currentApp: PropTypes.string.isRequired,
};

export default AppSwitcherItem;
