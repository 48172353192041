import actions from 'api/graph';
import mutation from '../useQueryWrapper';

const useMutateFetchGraphDrillDown = (
  id,
  page,
  chosenRegionId,
  businessUnitIds,
  businessTypeIds,
  startDate,
  endDate,
  isSorted,
  drillDownTitleKey,
  drillDownDataKey,
  drillDownQueryString,
) => {
  return mutation.useMutationWrapper(
    async () => {
    const res = await actions.fetchGraphDrillDown(
      id,
      page,
      chosenRegionId,
      businessUnitIds,
      businessTypeIds,
      startDate,
      endDate,
      isSorted,
      drillDownTitleKey,
      drillDownDataKey,
      drillDownQueryString,
    );
    return res.data;
    }
  );
};

export default useMutateFetchGraphDrillDown;
