import React from 'react';
import PropTypes from 'prop-types';

  // deprecated
const UIStaticComponentsWrapper = ({ children, extraSpace, isTopMargin = true, isAdmin }) => {
  return (
    <div
      className='h-full w-full'
    >
      {children}
    </div>
  );
};

UIStaticComponentsWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  extraSpace: PropTypes.bool,
  isTopMargin: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default UIStaticComponentsWrapper;
