import actions from 'api/graph';
import { useSetRecoilState } from 'recoil';
import { graphDefinitions } from 'state/KubenDashboard/graphs';
import query from '../useQueryWrapper';

const useFetchGraphDefinitions = () => {
  const setGraphDefinitions = useSetRecoilState(graphDefinitions);

  return query.useQueryWrapper('fetchGraphDefinitions', async () => {
      const res = await actions.fetchGraphDefinitions();
      setGraphDefinitions(res.data);
      return res.data;
  },
  {
    refetchOnWindowFocus: false,
  },
);

};

export default useFetchGraphDefinitions;
