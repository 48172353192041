/* eslint-disable max-len */
export const updateThemeStyles = (userColorTheme) => {

  if (!userColorTheme?.general) {
    return;
  }

  const root = document.documentElement;

  root.style.setProperty('--primary', userColorTheme.general.primary);
  root.style.setProperty('--secondary', userColorTheme.general.secondary);
  root.style.setProperty('--extraSecondary', userColorTheme.general.extraSecondary);
  root.style.setProperty('--tertiary', userColorTheme.general.tertiary);
  root.style.setProperty('--tertiary30', userColorTheme.general.tertiary30);
  root.style.setProperty('--accent', userColorTheme.general.accent);
  root.style.setProperty('--accentSecondary', userColorTheme.general.accentSecondary);
  root.style.setProperty('--counter', userColorTheme.general.counter);
  root.style.setProperty('--counterSecondary', userColorTheme.general.counterSecondary);
  root.style.setProperty('--appName', userColorTheme.general.appName);
  root.style.setProperty('--appBgColor', userColorTheme.general.appBgColor);
  root.style.setProperty('--appContentBgColor', userColorTheme.general.appContentBgColor);
  root.style.setProperty('--primaryHalf', userColorTheme.general.primaryHalf);
  root.style.setProperty('--primary30', userColorTheme.general.primary30);
  root.style.setProperty('--accentSecondary-30', userColorTheme.general.accentSecondary30);
  root.style.setProperty('--additional', userColorTheme.general.additional);
  root.style.setProperty('--black', userColorTheme.general.black);
  root.style.setProperty('--black30', userColorTheme.general.black30);
  root.style.setProperty('--gray', userColorTheme.general.gray);
  root.style.setProperty('--navigation-BgColor', userColorTheme.navigation.bgColor);
  root.style.setProperty('--navigation-sidebarItemColor', userColorTheme.navigation.sidebarItemColor);
  root.style.setProperty('--navigation-sidebarItemHoverColor', userColorTheme.navigation.sidebarItemHoverColor);
  root.style.setProperty('--navigation-sidebarItemHoverBgColor', userColorTheme.navigation.sidebarItemHoverBgColor);
  root.style.setProperty('--title-primary', userColorTheme.general.body);
  root.style.setProperty('--title-secondary', userColorTheme.general.primary);
  root.style.setProperty('--title-accent', userColorTheme.general.accent);
  root.style.setProperty('--title-white', userColorTheme.general.counterSecondary);
  root.style.setProperty('--subtitle-primary', userColorTheme.general.primary);
  root.style.setProperty('--subtitle-secondary', userColorTheme.textColors.subTitle.secondary);
  root.style.setProperty('--subtitle-additional', userColorTheme.textColors.subTitle.additional);
  root.style.setProperty('--subtitle-extra', userColorTheme.textColors.subTitle.extra);
  root.style.setProperty('--error-primary', userColorTheme.errors.primary);
  root.style.setProperty('--button-primary-bgColor', userColorTheme.general.primary);
  root.style.setProperty('--button-primary-textColor', userColorTheme.general.counterSecondary);
  root.style.setProperty('--button-primary-hoverBgColor', userColorTheme.general.secondary);
  root.style.setProperty('--button-primary-hoverTextColor', userColorTheme.general.primary);
  root.style.setProperty('--button-underlined-textColor', userColorTheme.general.counter);
  root.style.setProperty('--button-underlined-hoverTextColor', userColorTheme.general.primary);
  root.style.setProperty('--button-outlined-borderColor', userColorTheme.general.primary);
  root.style.setProperty('--button-outlined-textColor', userColorTheme.general.primary);
  root.style.setProperty('--button-outlined-bgColor', 'transparent');
  root.style.setProperty('--button-outlined-hoverBorderColor', userColorTheme.general.primary);
  root.style.setProperty('--button-outlined-hoverTextColor', userColorTheme.general.counterSecondary);
  root.style.setProperty('--button-outlined-hoverBgColor', userColorTheme.general.primary);
  root.style.setProperty('--button-collapse-color', userColorTheme.general.counter);
  root.style.setProperty('--button-collapse-borderColor', userColorTheme.general.counterSecondary);
  root.style.setProperty('--dropdown-primary-textColor', userColorTheme.general.primary);
  root.style.setProperty('--dropdown-primary-borderColor', userColorTheme.general.primary);
  root.style.setProperty('--dropdown-primary-bgColor', userColorTheme.general.secondary);
  root.style.setProperty('--dropdown-secondary-textColor', userColorTheme.general.primary);
  root.style.setProperty('--dropdown-secondary-borderColor', userColorTheme.general.primary);
  root.style.setProperty('--dropdown-secondary-bgColor', userColorTheme.general.secondary);
  root.style.setProperty('--link-element-bgColor', userColorTheme.general.extraSecondary);
  root.style.setProperty('--link-element-primary', userColorTheme.general.primary);
  root.style.setProperty('--link-element-secondary', userColorTheme.general.primary30);
  root.style.setProperty('--link-element-accent', userColorTheme.general.accent);
  root.style.setProperty('--link-element-counter', userColorTheme.general.counter);
};
