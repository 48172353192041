import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';

import { currentSelection } from 'state/KubenAnalytics/current/currentSelection';
import { selections } from 'state/KubenAnalytics/analytics/selections';

import UIContainerCard from '../../global/UICommon/UIContainerCard';
import UIAnalyticsButtonCheckbox from '../UIAnalytics/UIAnalyticsButtonCheckbox';
import UIButtonDropdown from '../UIAnalytics/UIButtonDropdown';

const AppGraphsSideBarFilters = () => {
  const wizardSelections = useRecoilValue(selections);
  const [selection, setSelection] = useRecoilState(currentSelection);
  const location = useLocation();

  const handleOnChange = (field, value) => {
    const tmpSelection = JSON.parse(JSON.stringify(selection));
    if (!tmpSelection[field]) {
      tmpSelection[field] = [];
    }
    if (tmpSelection[field].includes(value)) {
      tmpSelection[field] = tmpSelection[field].filter((el) => el != value);
      setSelection(tmpSelection);
      return;
    }
    if (field == 'unitIds' && isSelected(field, value)) {
      tmpSelection[field] = tmpSelection[field].filter((el) => el[0] != value[0]);
      setSelection(tmpSelection);
      return;
    }
    tmpSelection[field] = [...tmpSelection[field], value];
    setSelection(tmpSelection);
  };

  const isSelected = (field, value) => {
    if (!selection[field]) {
      return false;
    }
    if (field == 'unitIds') {
      return selection[field].some((unit) => unit[0] == value[0]);
    }
    return selection[field].includes(value);
  };

  return (
    <UIContainerCard>
      <div className="h-full p-3 lg:p-2 w-60">
        <div className="pt-2 pb-5 border-b border-tertiary text-lg text-left font-semibold text">Dina gjorda val</div>
        <div className="overflow-y-scroll max-h-91p">
          {wizardSelections?.filter((step) => step.choices.length > 0).map((step, idx) => (
              <div
                key={idx}
                className="py-2"
              >
                <UIButtonDropdown name={step?.step}>
                  {step?.choices.map((choice, idx) => (
                    <UIAnalyticsButtonCheckbox
                      key={idx}
                      onChange={(value) => handleOnChange(step.field, value)}
                      checked={isSelected(step.field, choice.value)}
                      value={choice.value}
                      name={choice.name}
                      group={choice.name}
                      isDisabled={location.pathname}
                    />
                  ))}
                </UIButtonDropdown>
              </div>
            ))}
        </div>
      </div>
    </UIContainerCard>
  );
};

export default AppGraphsSideBarFilters;
