import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { GrFormClose } from 'react-icons/gr';
import AppGraphsDatePicker from '../AppGraphs/AppGraphsDatePicker';
import { useSetRecoilState } from 'recoil';
import { IsOverlayActive } from 'state/global/overlay';
import closeElementFunction from 'utils/global/closeElementFunction';

const AppCustomDateModal = ({ closeModal, openModal }) => {
  const ref = useRef();
  const setOverlay = useSetRecoilState(IsOverlayActive);
  closeElementFunction(ref, closeModal);

  useEffect(() => {
    setOverlay(openModal);
  }, [openModal]);

  if (!openModal) {
    return null;
  }

  return (
    <div
      className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20'
      ref={ref}
    >
      <div className="p-5 border h-100 w-96 shadow-lg rounded-md background">
        <div className="w-full h-full relative background flex flex-col items-center justify-between">
          <p className="font-semibold text-2xl title">Välj period</p>
          <div
            onClick={closeModal}
            className="absolute top-0 right-0 cursor-pointer"
          >
            <GrFormClose className="w-6 h-6" />
          </div>
          <AppGraphsDatePicker />
        </div>
      </div>
    </div>
  );
};

AppCustomDateModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
};

export default AppCustomDateModal;
