import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import UISpinner from 'components/global/UICommon/UISpinner';

import Overview from 'components/icons/KubenDashboard/Overview';
import Settings from 'components/icons/KubenDashboard/Settings';
import HelpCenter from 'components/icons/KubenDashboard/HelpCenter';
import Contact from 'components/icons/KubenDashboard/Contact';
import SideBarMenuToggle from 'components/icons/KubenDashboard/SideBarMenuToggle';
import SideBarMenuItem from './SideBarMenuItem';

const SideBarMenu = ({ isEditLayout, title, name, items, icon, hasOverview=true}) => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const location = useLocation();

  const isLoading = !items;

  const isStartPage = () => {
    return name == '';
  };

  const isOverview = () => {
    return location.pathname.endsWith(`${name}/`) || location.pathname.endsWith(`${name}`);
  };

  return (
    <div
      className={`background ${isMenuOpen ? 'w-[18vw] max-w-[280px]' : 'w-[66px] xl:w-[70px]'}
        h-full flex flex-col transition-all duration-200 ease-in-out h-full relative z-50 navigation`}
    >
      <div className="w-full absolute items-center flex px-5 pt-2.5 z-10">
      <div className={`h-[20px] w-[20px] xl:h-[24px] xl:w-[24px] ml-1 my-1 mr-3 flex items-center flex-shrink-0`}>
      {icon}
        </div>
        {isMenuOpen && <div className="text-lg font-medium app-name">{title}</div>}
        <div
          className={`h-[22px] w-[22px] xl:h-[26px] xl:w-[26px] absolute
          transition-all duration-150 ease-in-out rounded-md collapse-icon
           ${isMenuOpen ? 'right-5' : '-right-7 rotate-180'}`}
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          <SideBarMenuToggle/>
        </div>
      </div>
      <div className="flex flex-col pb-16 pt-24 h-full pl-5">
        <div className="flex flex-col space-y-5 w-full h-full scroll-on-hover overflow-x-hidden pr-5">
          {hasOverview && <SideBarMenuItem
            title="Översikt"
            icon={<Overview />}
            isMenuOpen={isMenuOpen}
            isActive={isOverview()}
            link={`/${name}`}
          />}
          {isLoading ? (
            <div>
              <UISpinner theme="fast" />
            </div>
          ) : (
            items.filter(item => !item.isDisabled)
            .map((item, idx) => (
              <SideBarMenuItem
                key={idx}
                title={item.title}
                isMenuOpen={isMenuOpen}
                defaultOpen={item.defaultOpen}
                isActive={location.pathname.endsWith(`${name}/${item.name}`)}
                icon={item.icon}
                link={`/${name}/${item.name}`}
              >
                  {item.children?.filter(item => !item.isDisabled)
                  .map((child, idx) => (
                    <SideBarMenuItem
                      key={idx}
                      title={child.title}
                      isActive={!isStartPage() && location.pathname.endsWith(`${name}/${child.name}`)}
                      isMenuOpen={isMenuOpen}
                      icon={child.icon}
                      editIcon={child.editIcon}
                      editClick={child.editClick}
                      onDragStart={child.onDragStart}
                      isEditLayout={isEditLayout}
                      link={`/${isStartPage() ? item.name : name}/${child.name}`}
                      isSecondaryButton={true}
                    />
                  ))}
              </SideBarMenuItem>
            ))
          )}
          <div className="flex items-end h-full">
            <div className="flex flex-col space-y-2 w-full">
              <SideBarMenuItem
                title="Inställningar"
                icon={<Settings />}
                isMenuOpen={isMenuOpen}
                link="/settings/"
              />
              <SideBarMenuItem
                title="Hjälpcenter"
                icon={<HelpCenter />}
                isMenuOpen={isMenuOpen}
                href='https://kubens-help.azurewebsites.net/'
              />
              <SideBarMenuItem
                title="Kontakta oss"
                icon={<Contact />}
                isMenuOpen={isMenuOpen}
                href="https://kubens-help.azurewebsites.net/kontakt/"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SideBarMenu.propTypes = {
  isEditLayout: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  items: PropTypes.array,
  icon: PropTypes.node,
  hasOverview: PropTypes.bool,
};

export default SideBarMenu;
