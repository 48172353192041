import { React } from 'react';
import PropTypes from 'prop-types';

const UIButtonCheckbox = ({ description, checked, setChecked, isDisabled, styleDesc = 'text-22px font-semibold' }) => {
  if (isDisabled && description) {
    return null;
  }

  return (
    <div className="flex flex-col text-left items-start button-checkbox">
      <input
        type="checkbox"
        className="hidden assessment-checkbox"
        checked={checked}
        readOnly={true}
      />
      <label
        onClick={() => setChecked(!checked)}
        className={`flex relative items-center cursor-pointer text-sm py-2 w-full ` + `w-full text-left`}
      >
        <span className={`${checked ? 'checked' : ''} ` + `w-5 h-5 inline-block mr-2 rounded border-2 `} />
        {description && <div className={`title secondary ${styleDesc}`}>{description}</div>}
      </label>
    </div>
  );
};

UIButtonCheckbox.propTypes = {
  description: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  styleDesc: PropTypes.string,
};

export default UIButtonCheckbox;
