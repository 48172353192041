/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import formatDate from 'utils/global/formatDate';

import useDeleteAssessment from 'hooks/api/assessmentResult/useDeleteAssessment';
import useFetchAllIncompleteAssessments from 'hooks/api/assessmentResult/useFetchAllIncompleteAssessments';
import useFetchAllHistoryAssessment from 'hooks/api/assessmentResult/useFetchAllHistoryAssessment';
import useFetchCompletedAssessment from 'hooks/api/assessmentResult/useFetchCompletedAssessment';
import useFetchTemplateAssessment from 'hooks/api/assessment/useFetchTemplateAssessment';
import useSaveAssessmentEndDate from 'hooks/api/assessmentResult/useSaveAssessmentEndDate';
import useFetchAllHistoryAssessmentForManager from 'hooks/api/assessmentResult/useFetchAllHistoryAssessmentForManager';
import useCreateReassessmentFromCompletedAssessment from 'hooks/api/assessmentResult/useCreateReassessmentFromCompletedAssessment';

import { currentUser } from 'state/global/currentUser';
import { chosenTemplate as chosenTemplateRecoil } from 'state/global/templates';
import { chosenRegion as chosenRegionRecoil } from 'state/KubenAssessments/choosingTemplate/region';
import { chosenAssessmentAction as chosenAssessmentActionRecoil } from 'state/KubenAssessments/chosenAssessmentAction/chosenAssessmentAction';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';
import { selectedPerson as selectedPersonRecoil } from 'state/KubenAssessments/saved/savedSelectedPerson';
import { latestAnsweredAssessment } from 'state/KubenAssessments/questions/latestAnsweredAssessment';
import { allIncompleteAssessments as allIncompleteAssessmentsRecoil } from 'state/KubenAssessments/assessments/allIncompleteAssessments';
import { allAssessmentHistory as allAssessmentHistoryRecoil } from 'state/KubenAssessments/assessmentHistory/allAssessmentHistory';
import { currentQuestionState } from 'state/KubenAssessments/questions/currentQuestions';
import { allAssessmentHistoryForManager as allAssessmentHistoryForManagerRecoil } from 'state/KubenAssessments/assessmentHistory/allAssessmentHistoryForManager';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import DashboardElementsHeader from 'components/global/DashboardElements/DashboardElementsHeader';
import AssessmentDasboardLinkCard from 'components/global/DashboardElements/DashboardElementsLinkCard';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIWarningModal from 'components/global/UIModals/UIWarningModal';

import AssessmentDashboardListCard from 'components/KubenAssessments/AssessmentDashboardPage/AssessmentDashboardListCard';
import AssessmentHistoryDateModal from 'components/KubenAssessments/AssessmentHistoryPage/AssessmentHistoryDateModal';
import ChoosePersonImage from 'components/icons/global/ChoosePersonImage';
import ChooseTemplateImage from 'components/icons/global/ChooseTemplateImage';
import SearchImage from 'components/icons/global/SearchImage';

const AssessmentsDashboardPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useRecoilValue(currentUser);

  const onSuccess = () => {
    history.push('/assessments/questions');
  };

  // warning modal mechanism
  const [openWarningModal, setOpenWarningModal] = useState();
  const [elementToDelete, setElementToDelete] = useState();
  const [elementTypeToDelete, setElementTypeToDelete] = useState();

  // date modal - set endDate properties
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});
  const [assessmentEndDateData, setAssessmentEndDateData] = useState('');

  // lists mechanism
  const {
    isLoading: isAllIncompleteAssessmentsLoading,
    isError: isAllIncompleteAssessmentsError,
    mutate: mutateAllIncompleteAssessments,
  } = useFetchAllIncompleteAssessments(1, 5);
  const {
    isLoading: isAllHistoryAssessmentsLoading,
    isError: isAllHistoryAssessmentsError,
    mutate: mutateFetchAllHistoryAssessments,
    data: allHistoryAssessmentData,
  } = useFetchAllHistoryAssessment(1, 5);
  const {
    isLoading: isAllHistoryAssessmentsForManagerLoading,
    isError: isAllHistoryAssessmentsForManagerError,
    mutate: mutateFetchAllHistoryAssessmentsForManager,
  } = useFetchAllHistoryAssessmentForManager(1, 5);
  const {
    isLoading: isDeletingAssessmentLoading,
    isError: isDeletingAssessmentError,
    isSuccess: isDeleteAssessmentSuccess,
    mutate: deleteIncompleteAssessment,
  } = useDeleteAssessment(elementToDelete, elementTypeToDelete);
  const allIncompleteAssessments = useRecoilValue(allIncompleteAssessmentsRecoil);
  const allHistoryAssessmentsForManager = useRecoilValue(allAssessmentHistoryForManagerRecoil);
  const allHistoryAssessment = useRecoilValue(allAssessmentHistoryRecoil);

  // tab mechanism
  const [isMineAssessments, setIsMineAssessments] = useState(true);
  const [assessmentsDataForTab, setAssessmentsDataForTab] = useState([]);

  // setting data for continue assessment
  const [chosenRegion, setChosenRegion] = useRecoilState(chosenRegionRecoil);
  const [chosenTemplate, setChosenTemplate] = useRecoilState(chosenTemplateRecoil);
  const setChosenAssesmentAction = useSetRecoilState(chosenAssessmentActionRecoil);
  const setAnswersAssessment = useSetRecoilState(answersAssessment);
  const setSelectedPerson = useSetRecoilState(selectedPersonRecoil);

  // resetting all data while entering page
  const resetChosenRegion = useResetRecoilState(chosenRegionRecoil);
  const resetChosenTemplate = useResetRecoilState(chosenTemplateRecoil);
  const resetChosenAssesmentAction = useResetRecoilState(chosenAssessmentActionRecoil);
  const resetAnswersAssessment = useResetRecoilState(answersAssessment);
  const resetSelectedPerson = useResetRecoilState(selectedPersonRecoil);
  const resetCurrentQuestion = useResetRecoilState(currentQuestionState);
  const resetLatestAnsweredAssessment = useResetRecoilState(latestAnsweredAssessment);

  // dropdown options mechanism
  const {
    isLoading: isAssessmentResultLoading,
    isError: isAssessmentResultError,
    mutate: mutateAssessmentResult,
  } = useFetchCompletedAssessment({ onSuccess });
  const {
    isLoading: isCreateReassessmentFromCompletedAssessmentLoading,
    isError: isCreateReassessmentFromCompletedAssessmentError,
    mutate: mutateCreateReassessmentFromCompletedAssessment,
  } = useCreateReassessmentFromCompletedAssessment({ onSuccess });
  const {
    isLoading: isTemplateAssessmentLoading,
    isError: isTemplateAssessmentError,
    mutate: mutateTemplateAssessment,
  } = useFetchTemplateAssessment(chosenTemplate?.id, chosenRegion?.id);
  const {
    isLoading: isSetEndDateLoading,
    isError: isSetEndDateError,
    mutate: mutateSetEndDate,
    isSuccess: isSetEndDateSuccess,
  } = useSaveAssessmentEndDate(assessmentEndDateData);
  const [isRedirectLoading, setIsRedirectLoading] = useState(false);

  // reset all data while entering page
  useEffect(() => {
    resetChosenRegion();
    resetChosenTemplate();
    resetChosenAssesmentAction();
    resetAnswersAssessment();
    resetSelectedPerson();
    resetCurrentQuestion();
    resetLatestAnsweredAssessment();
    setIsRedirectLoading(false);
  }, []);

  // fetch completed and initial assessments
  useEffect(() => {
    mutateAllIncompleteAssessments();
    mutateFetchAllHistoryAssessments();
    mutateFetchAllHistoryAssessmentsForManager();
  }, []);

  // set data for table at inital
  useEffect(() => {
    if (allHistoryAssessmentData != null) {
      setAssessmentsDataForTab(allHistoryAssessment?.results);
    }
  }, [allHistoryAssessmentData]);

  // fetch completed and initial assessments
  useEffect(() => {
    if (isSetEndDateSuccess) mutateFetchAllHistoryAssessments();
  }, [isSetEndDateSuccess]);

  // refetch assessments after deleting one
  useEffect(() => {
    if (isDeleteAssessmentSuccess) {
      mutateAllIncompleteAssessments();
      mutateFetchAllHistoryAssessments();
    }
  }, [isDeleteAssessmentSuccess]);

  // dropdown options actions
  useEffect(() => {
    if (chosenRegion?.id && chosenTemplate?.id) {
      mutateTemplateAssessment();
    }
    if (assessmentEndDateData) {
      mutateSetEndDate();
    }
  }, [chosenRegion, chosenTemplate, assessmentEndDateData]);

  // tabs mechanism
  const showAllAssessments = () => {
    setIsMineAssessments(false);
    setAssessmentsDataForTab(allHistoryAssessmentsForManager?.results);
  };

  const showMineAssessments = () => {
    setIsMineAssessments(true);
    setAssessmentsDataForTab(allHistoryAssessment?.results);
  };

  // fuction for endDate modal
  const handleDateModalClick = (action, assessmentId, assessmentEndDate, reasonEndDay) => {
    if (action === 'closeModal') setIsDateModalOpen(false);
    if (action === 'setEndDate') {
      setAssessmentEndDateData({
        id: assessmentId,
        date: assessmentEndDate !== null ? formatDate(assessmentEndDate, 'yyyy-MM-dd') : null,
        reason: reasonEndDay,
      });
      setIsDateModalOpen(false);
    }
  };

  const onDeleteElement = () => {
    deleteIncompleteAssessment();
    setOpenWarningModal(false);
  };

  const handleClick = (action, element) => {
    const id = element?.id;
    switch (action) {
      case 'deleteIncomplete':
      case 'deleteResult':
        setOpenWarningModal(true);
        setElementToDelete(id);
        setElementTypeToDelete(action);
        break;
      case 'goToPerson':
        setSelectedPerson(element.forPerson);
        history.push('/assessments/choose-assessments');
        break;
      case 'continue':
        setChosenAssesmentAction('existingAssessment');
        setIsRedirectLoading(true);
        setChosenRegion(element.region);
        setChosenTemplate(element.assessment);
        setAnswersAssessment(element);
        setSelectedPerson(element.forPerson != null && element.forPerson);
        setTimeout(() => {
          history.push('/assessments/questions');
        }, 200);
        return;
      case 'reassess':
        mutateCreateReassessmentFromCompletedAssessment({ id });
        setSelectedPerson(element.forPerson != null && element.forPerson);
        return;
      case 'changeAnswers':
        mutateAssessmentResult({ id });
        setSelectedPerson(element.forPerson != null && element.forPerson);
        return;
      case 'showSummary':
        setIsRedirectLoading(true);
        // save all necessary data for summary page
        setChosenAssesmentAction('summaryReadonly');
        setAnswersAssessment(element);
        setChosenRegion(element?.region);
        setChosenTemplate(element?.assessment);
        setTimeout(() => {
          history.push('/assessments/questions-summary');
        }, 500);
        return;
      case 'changeStopDate':
        setAssessmentData(element);
        setIsDateModalOpen(true);
        return;
    }
  };

  if (
    isAllIncompleteAssessmentsLoading ||
    isCreateReassessmentFromCompletedAssessmentLoading ||
    isDeletingAssessmentLoading ||
    isSetEndDateLoading ||
    isRedirectLoading ||
    isAllHistoryAssessmentsLoading ||
    isAssessmentResultLoading ||
    isTemplateAssessmentLoading ||
    isAllHistoryAssessmentsForManagerLoading ||
    assessmentsDataForTab === undefined
  ) {
    return (
      <UILoadingModal
      />
    );
  }

  if (
    isDeletingAssessmentError ||
    isSetEndDateError ||
    isAssessmentResultError ||
    isCreateReassessmentFromCompletedAssessmentError ||
    isTemplateAssessmentError ||
    isAllHistoryAssessmentsForManagerError
  ) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper>
      <UIWarningModal
        isOpen={openWarningModal}
        setOpen={setOpenWarningModal}
        onClick={onDeleteElement}
        modalTitle={t('UIButtons.edit')}
        modalMessage={t('UIModals.editModalMessage')}
        confirmButtonName={t('UIButtons.edit')}
      />
      <div className="h-full w-full max-w-[1256px] mx-auto mt-10 md:pl-[55px] lg:pl-12 xl:pl-12 md:pr-6 lg:pr-6 assessmentPage">
        <DashboardElementsHeader name={user.name} />
        <div className="flex w-full py-10 space-x-2 h-[80%] md:h-full lg:h-full startsidaTable">
          <div className="w-2/5 space-y-2 text-lg block h-auto primary md:h-[735px]">
            <AssessmentDasboardLinkCard
              variant="height"
              link="/assessments/choose-person"
              isDisabled={!user?.hasAccessToPersonAssessment}
            >
              <div
                className={`
                  ${user?.hasAccessToPersonAssessment ? 'primary transition-all group-hover:scale-105' : 'subtitle extra'} text-4xl my-4
                  transition-all group-hover:scale-105 h-2/3
                  `}
              >
                <ChoosePersonImage />
              </div>
              {t('AssessmentDashboardPage.links.assessments')}
            </AssessmentDasboardLinkCard>
            <div className="grid xl:flex h-1/2 xl:space-x-2 space-y-2 xl:space-y-0">
              <AssessmentDasboardLinkCard
                isDisabled={!user?.hasAccessToUserAssessment}
                variant="width"
                link="/assessments/choose-template"
              >
                <div className={`h-2/3 text-4xl my-4 ${user?.hasAccessToUserAssessment ? 'primary transition-all group-hover:scale-105' : 'subtitle extra'}
                  
                `}>
                  <ChooseTemplateImage />
                </div>
                {t('AssessmentDashboardPage.links.timming')}
              </AssessmentDasboardLinkCard>
              <AssessmentDasboardLinkCard
                variant="width"
                link="/assessments/search"
                isDisabled={!user?.hasAccessToSearch}
              >
                <div className={`${user?.hasAccessToSearch ? 'primary transition-all group-hover:scale-105' : 'subtitle extra'} text-4xl my-4 h-2/3`}>
                  <SearchImage />
                </div>
                {t('AssessmentDashboardPage.links.search')}
              </AssessmentDasboardLinkCard>
            </div>
          </div>
          <div className="w-3/5 space-y-2 h-auto md:h-[770px] lg:h-[708px]">
            <AssessmentDashboardListCard
              title={t('AssessmentDashboardPage.startedAssessment.header')}
              data={allIncompleteAssessments?.results}
              urlLink="/assessments/assessment-history/initial"
              type="started"
              handleClick={handleClick}
              isError={isAllIncompleteAssessmentsError}
            />
            <AssessmentDashboardListCard
              title={
                isMineAssessments ? t('AssessmentDashboardPage.finishedAssessment.header') : 'Alla tidigare bedömningar'
              }
              data={assessmentsDataForTab}
              urlLink={isMineAssessments ? '/assessments/assessment-history/completed' : '/assessments/assessment-history/all-completed'}
              type="history"
              handleClick={handleClick}
              isError={isMineAssessments ? isAllHistoryAssessmentsError : isAllHistoryAssessmentsForManagerError}
              isTab={true}
              showFirstTab={showMineAssessments}
              showSecondTab={showAllAssessments}
              activeTab={isMineAssessments}
            />
          </div>
        </div>
        {isDateModalOpen && (
          <div className="w-[560px] h-full">
            <AssessmentHistoryDateModal
              isOpen={isDateModalOpen}
              assessmentData={assessmentData}
              handleClick={(action, id, date, reason) => handleDateModalClick(action, id, date, reason)}
            />
          </div>
        )}
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default AssessmentsDashboardPage;
