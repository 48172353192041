import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import useFetchGraphFilters from 'hooks/api/graph/useFetchGraphFilters';
import useFetchGraphRegions from 'hooks/api/graph/useFetchGraphRegions';
import { graphFilters, graphRegion } from 'state/KubenDashboard/graphFilters';
import { isDashboardScroll } from 'state/KubenDashboard/filter';
import { graphDrillDownData } from 'state/KubenDashboard/graphs';
import { IsOverlayActive } from 'state/global/overlay';
import extractFiltersNames from 'utils/KubenDashboard/extractFiltersName';

import UISpinner from 'components/global/UICommon/UISpinner';
import UIErrorMessage from 'components/global/UICommon/UIErrorMessage';
import GraphPeriodFilter from './GraphFilters/GraphPeriodFilter';
import GraphFilters from './GraphFilters/GraphFilters';
import DashboardsRegionSwitcher from './TopPanel/RegionSwitcher/DashboardRegionSwitcher';
import GraphFilterDropdownElementItem from './GraphFilters/GraphFilterDropdownElementItem';

const DashboardFilterPanel = ({width}) => {
  const isScroll = useRecoilValue(isDashboardScroll);
  const resetChosenGraphFilters = useResetRecoilState(graphFilters);
  const chosenGraphFilters = useRecoilValue(graphFilters);
  const [drillDownData, setDrillDownData] = useRecoilState(graphDrillDownData);
  const [chosenGraphRegion, setChosenGraphRegion] = useRecoilState(graphRegion);
  const [regionNodes, setRegionNodes] = useState([]);
  const isOverlay = useRecoilValue(IsOverlayActive);
  const [maxRegionNodes, setMaxRegionNodes] = useState(8);
  const {
    isLoading: isGraphRegionsLoading,
    isError: isGraphRegionsError,
    isSuccess: isGraphRegionsSuccess,
    data: graphRegions,
  } = useFetchGraphRegions();
  const {
    isLoading: isGraphFiltersLoading,
    isSuccess: isGraphFiltersSuccess,
    isError: isGraphFiltersError,
    data: graphFiltersData,
    refetch,
  } = useFetchGraphFilters(chosenGraphRegion.id);

  const isLoading = (!isGraphFiltersSuccess || isGraphFiltersLoading ||
     Object.keys(chosenGraphRegion).length === 0 || isGraphRegionsLoading);
  const isError = isGraphFiltersError || isGraphRegionsError;

  const saveGraphRegion = (region) => {
    setDrillDownData({ ...drillDownData, data: 0, title: 0 });
    setChosenGraphRegion(region);
  };

  useEffect(() => {
    if (width) {
      if (width < 1000) {
        setMaxRegionNodes(4);
      } else if (width < 1500) {
        setMaxRegionNodes(6);
      } else if (width < 2000) {
        setMaxRegionNodes(8);
      } else {
        setMaxRegionNodes(10);
      }
    }
  }, [width]);

  useEffect(() => {
    resetChosenGraphFilters();
  }, [chosenGraphRegion]);

  useEffect(() => {
    if (graphFiltersData) {
      setRegionNodes(extractFiltersNames(graphFiltersData.regionNodes, chosenGraphFilters['regionNodes']));
    }
  }, [chosenGraphFilters]);


  useEffect(() => {
    if (isGraphRegionsSuccess) {
      setChosenGraphRegion(graphRegions[0]);
    }
  }, [isGraphRegionsSuccess]);

  useEffect(() => {
    if (chosenGraphRegion.id != null) {
      refetch();
    }
  }, [chosenGraphRegion]);

  return (
    <div
      style={{
        width: `${width}px`,
      }}
      className={`
         ${isScroll ? 'top-2 pr-14 pl-5' : (`${regionNodes.length > 0 ? 'top-14' : 'top-20'} px-5`)}
         fixed h-7 z-10 ease-in-out text-xs
        transition-all duration-500`}
    >
      {isLoading ?
          <UISpinner theme="fast" />
      : isError ? (
        <UIErrorMessage />
      ) : (
        <div
          className='flex flex-col'
        >
          <div
            className='w-full flex items-center justify-between'
          >
            <GraphFilters
                isScroll={isScroll}
                filters={graphFiltersData}
            />
            <div
              className='flex space-x-4'
            >
              <GraphPeriodFilter isScroll={isScroll} />
              <DashboardsRegionSwitcher
                regions={graphRegions}
                chosenRegion={chosenGraphRegion}
                onRegionChange={saveGraphRegion} />
            </div>
        </div>
        <div
          className={`h-10 items-center overflow-hidden opacity-60
            ${isScroll || isOverlay ? 'hidden' : 'flex'} 
          `}
        >
          {regionNodes.slice(0, maxRegionNodes).map((node, idx) => 
          <GraphFilterDropdownElementItem
            key={idx}
            id={node.id}
            name={node.name}
          />
          )}
          {regionNodes.length > maxRegionNodes &&
           <GraphFilterDropdownElementItem
              id={-1}
              name={`+${regionNodes.length - maxRegionNodes}`}
            />
          }
        </div>
        </div>
      )}
    </div>
  );
};

DashboardFilterPanel.propTypes = {
  width: propTypes.number,
  isScroll: propTypes.bool,
};

export default DashboardFilterPanel;
