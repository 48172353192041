import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentColorTheme } from 'state/global/currentUser';
import { updateThemeStyles } from 'utils/global/updateThemeStyles';
import StartPage from '../../../pages/global/StartPage';
import PageLogin from '../../../pages/global/PageLogin';
import PageError from '../../../pages/global/PageError';
import ApplicationNotWorking from '../../../pages/global/ApplicationNotWorking';
import SettingsPage from 'pages/global/SettingsPage';
import AppAuthGuard from './AppAuthGuard';
import AppAssessmentLayout from './AppAssessmentLayout';
import AppAnalyticsLayout from './AppAnalyticsLayout';
import AppAdminLayout from './AppAdminLayout';
import AppReportLayout from './AppReportsLayout';
import AppDashboardLayout from './AppDashboardLayout';
import SideBarMenuWrapper from '../Menu/SideBarMenuWrapper';

const AppLayout = () => {
  const theme = useRecoilValue(currentColorTheme);

  useEffect(() => {
    if (theme) {
      updateThemeStyles(theme);
    }
  }, [theme]);

  const MainLayout = () => (
    <SideBarMenuWrapper>
      <Switch>
        <Route exact path="/" component={StartPage} />
        <Route exact path="/settings" component={SettingsPage} />
        <Route path="/assessments" component={AppAssessmentLayout} />
        <Route path="/analytics" component={AppAnalyticsLayout} />
        <Route path="/admin" component={AppAdminLayout} />
        <Route path="/reports" component={AppReportLayout} />
        <Route path="/dashboard" component={AppDashboardLayout} />
        <Route component={() => <Redirect to="/error/404" />} />
      </Switch>
    </SideBarMenuWrapper>
  );

  return (
    <Router>
      <AppAuthGuard>
        {process.env.REACT_APP_DISRUPTION ? (
          <Route path="/" component={ApplicationNotWorking} />
        ) : (
          <>
            <Switch>
              <Route path="/error" component={PageError} />
              <Route exact path="/login" component={PageLogin} />
              <Route path="/" component={MainLayout} />
              <Route component={() => <Redirect to="/error/404" />} />
            </Switch>
          </>
        )}
      </AppAuthGuard>
    </Router>
  );
};

export default AppLayout;
