import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiSearch, FiX } from 'react-icons/fi';
import UIInputValidationMessage from './UIInputValidationMessage';

import UIConfirmModal from 'components/KubenAdmin/UIAdmin/UIConfirmModal';

const UIInput = ({
  type = 'text',
  value,
  onChange,
  name,
  placeholder,
  validationMessage,
  height,
  radius = 'rounded-lg',
  disabled,
  isRequired,
  isPasswordHidden,
  isAutoComplete = true,
  onSubmit,
  onDelete,
}) => {
  const [isDelete, setDelete] = useState(false);

  const setDeletePopup = (e) => {
    e.stopPropagation();
    setDelete(true);
  };
  const confirmDelete = () => {
    onDelete();
    setDelete(false);
  };

  // eslint-disable-next-line max-len
  validationMessage =
    validationMessage ||
    (isRequired && !value && (type === 'number' ? 'Ange ett numeriskt värde.' : 'Detta fältet är obligatoriskt.'));

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmit(event);
    }
  };

  if (type === 'search') {
    return (
      <div className={`flex items-center relative input w-full h-full rounded-md border border-tertiary`}>
        <FiSearch className="absolute ml-3" />
        <input
          value={value}
          onKeyDown={handleKeyPress}
          onChange={(e) => onChange(e.target.value)}
          className="pl-10 py-1 outline-none text-lg w-full h-full mt-1"
          placeholder={placeholder}
        />
      </div>
    );
  } else if (type === 'file') {
    return (
      <div className="flex items-center relative w-full h-full rounded-md">
        <input
          type="file"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="outline-none text-lg w-full input-file"
          placeholder={placeholder}
        />
      </div>
    );
  } else if (type === 'admin') {
    return (
      <div className="flex items-center relative input w-full h-full rounded-md">
        <input
          value={value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
          className={`px-4 py-2 border input-light outline-none text-lg w-full ${disabled ? 'cursor-not-allowed' : ''}`}
          placeholder={placeholder}
        />
      </div>
    );
  } else if (type === 'number') {
    return (
      <>
        <input
          type="number"
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          step={1}
          value={value}
          onChange={(e) => onChange(e)}
          className={`${radius} py-2 px-6 w-full border ${disabled ? 'opacity-60' : 'input-number '}
           text-20px ${height}`}
        />
        {validationMessage && (
          <div className="mt-1 z-20">
            <UIInputValidationMessage validationMessage={validationMessage} />
          </div>
        )}
      </>
    );
  } else if (type === 'text') {
    return (
      <div className="relative w-full group">
        <input
          autoComplete={isAutoComplete ? 'on' : 'off'}
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          onKeyDown={handleKeyPress}
          value={value || ''}
          onChange={(e) => onChange(e)}
          className={`${radius} py-2 px-6 w-full border input-light ${
            disabled ? 'opacity-60 cursor-not-allowed' : ' '
          } text-20px ${height}`}
        />
        {onDelete && (
          <FiX
            title="Radera"
            // eslint-disable-next-line max-len
            className="absolute right-5 top-1/2 transform -translate-y-1/2 text-2xl cursor-pointer opacity-0 group-hover:opacity-60 group-hover:hover:opacity-40"
            onClick={setDeletePopup}
          />
        )}
        {isDelete && (
          <UIConfirmModal
            title={`Vill du ta bort '${value}'?`}
            onConfirm={confirmDelete}
            onCancel={() => setDelete(false)}
          />
        )}
        {validationMessage && (
          <div className="mt-1 z-20">
            <UIInputValidationMessage validationMessage={validationMessage} />
          </div>
        )}
      </div>
    );
  } else if (type == 'password') {
    return (
      <div className="flex items-center relative w-full h-full input rounded-md">
        <input
          type={isPasswordHidden ? 'password' : 'text'}
          autoComplete="new-password"
          value={value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
          className={`px-4 py-2 border input-light outline-none text-lg w-full ${disabled ? 'cursor-not-allowed' : ''}`}
          placeholder={placeholder}
        />
      </div>
    );
  }
  return null;
};

UIInput.propTypes = {
  type: PropTypes.oneOf(['search', 'text', 'number', 'file', 'admin', 'password']).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.any,
  validationMessage: PropTypes.string,
  name: PropTypes.string,
  height: PropTypes.string,
  radius: PropTypes.string,
  isPasswordHidden: PropTypes.bool,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isAutoComplete: PropTypes.bool,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default UIInput;
